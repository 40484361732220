import React from 'react'
import Reviews from '../../Components/Testimony/Testimony'



function Testimony() {
  return (
    <>
        <Reviews />
    </>
  )
}

export default Testimony