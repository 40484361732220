import React from 'react'
import About from '../../Components/Aboutus/AboutUs'

function AboutUs() {
  return (
    <>
      <About />
    </>
  )
}

export default AboutUs