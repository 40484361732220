import React, { useState, useEffect } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import GetStarted from "./Pages/GetStarted/GetStarted";
import Home from "./Pages/Home/Home";
// import Projects from "./Pages/Projects/Projects";
// import PianoMusic from "./Pages/PianoMusic/PianoMusic";
import AboutUs from "./Pages/AboutUS/AboutUs";
import Login from "./Pages/Home/Login";
import PreOrder from "./Pages/PreOrder/PreOrder";
import { useAuthContext } from "./hooks/useAuthContext";
import SignUp from "./Pages/Home/SignUp";
import GetaRobotMobile from "./Pages/Home/GetaRobotMobile";
import User from "./Pages/User/User";
import CodeIt from "./Pages/CodeIt/CodeIt";
import ProjectExpanded from "./Pages/Projects/ProjectExpanded/ProjectExpanded";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import LanguageSelectionPopup from "./Components/LanguageSelectionPopup";
import magicRobo from "./Pages/MagicRobo/magicRobo";

//MUI
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes
} from "@mui/material/styles";
import Projects from "./Components/Project/Projects";
let theme = createTheme({
  palette: {
    primary: {
      main: "#009DEB"
    },
    secondary: {
      main: "#FFF"
    },
    background: {
      default: "#F2F7F9"
      // paper: "#009DEB10",
    },
    text: {
      primary: "#000",
      secondary: "#676767"
    }
  },
  typography: {
    fontFamily: "Montserrat",
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 800
  }
});

//Responsive font sizing
theme = responsiveFontSizes(theme);

function App() {
  const { authIsReady } = useAuthContext();
  const { t, i18n } = useTranslation();
  const [showLanguageSelection, setShowLanguageSelection] = useState(true);

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
      setShowLanguageSelection(false);
    } else {
      getUserLocation().then((location) => {
        if (location === "uae") {
          i18n.changeLanguage("ar");
        }
      });
    }
  }, [i18n]);

  const handleLanguageSelect = (language) => {
    i18n.changeLanguage(language);
    setShowLanguageSelection(false);
    localStorage.setItem("language", language);
  };

  function getUserLocation() {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude } = position.coords;

            if (latitude >= 22 && latitude <= 26) {
              resolve("uae");
            } else {
              resolve("other");
            }
          },
          (error) => {
            console.error("Error getting user location:", error);
            resolve("other");
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser");
        resolve("other");
      }
    });
  }

  return (
    <>
      {authIsReady && (
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <div>
              <Header
                handleLanguageSelect={handleLanguageSelect}
                showLanguageSelection={showLanguageSelection}
                setShowLanguageSelection={setShowLanguageSelection}
              />
              <Route exact path="/" component={Home} />
              <Route path="/get-started" component={magicRobo} />

              <Route exact path="/projects" component={Projects} />
              {/* <Route path="/projects/piano-music" component={PianoMusic} /> */}
              <Route path="/about-us" component={AboutUs} />
              <Route path="/preorder" component={PreOrder} />
              <Route path="/user" component={User} />
              <Route path="/codeit" component={CodeIt} />
              <Route
                exact
                path="/projects/:projectTitle"
                component={ProjectExpanded}
              />
              <Route path="/login" component={Login} />
              <Route path="/signup" component={SignUp} />
              <Route path="/getarobot" component={GetaRobotMobile} />
              <Footer />
              {showLanguageSelection && (
                <LanguageSelectionPopup onSelect={handleLanguageSelect} />
              )}
            </div>
          </BrowserRouter>
        </ThemeProvider>
      )}
    </>
  );
}

export default App;
