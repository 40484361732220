import React from "react";
import Features from "./Features";
import Innovation from "./Innovation.js";
import Products from "./Products.js";
import Testimony from "./Testimony.js";
import Software from "./Software.js";
import Introduction from "./Introduction.js";

function Home() {
  return (
    <>
      <Introduction />
      <Innovation />
      <Features />
      <Products />
      <Testimony />
      <Software />
    </>
  );
}

export default Home;
