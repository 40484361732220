import { functions } from "./config.js";
import { loadStripe } from '@stripe/stripe-js';
import { httpsCallable } from "firebase/functions";

const loadCheckout = async (priceId, productName, user) => {

  // if(user == null)
  // {
  //   try{
  //   user = (await auth.signInWithEmailAndPassword('autobonicsllc@gmail.com', 'mytestpass')).user;
  //   }catch(error)  {
  //     var errorMessage = error.message;
  //     console.log(`error login with user ${errorMessage}`);
  //     return;
  //   }
  // }

  console.log("Calling the checkout with the user with id:", user.uid);
  const createStripeCheckout = httpsCallable(functions, 'createStripeCheckout');
  createStripeCheckout({ priceId: priceId, productName: productName })
    .then(async (response) => {
      const sessionId = response.data.sessionId;
      console.log(`The id value returned is ${sessionId}`);
      if (sessionId) {
        const stripe = await loadStripe("pk_live_51McsegCiqRMSzN2Kljqw8ZpbaF6AJDIjg2KO6aTkJzHmjHQmgBzA16SKA5gqnpYzIaBqBRc76MYyxsmEnttdcfJw00GiRkHP2i");
        await stripe.redirectToCheckout({ sessionId });
      }
    })
    .catch((error) => {
      // Getting the Error details.
      var message = error.message;
      var details = error.details;
      console.log(`error calling the stripe function ${message}, ${details}`);
      // ...
    });

}

export { loadCheckout };