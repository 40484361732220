import React from "react";
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Card,
  Grid,
  Container,
  Typography,
  CardMedia,
  CardContent,
  Divider,
  createTheme,
  ThemeProvider
} from "@mui/material";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1200,
      lg: 1500,
      xl: 1836,
    },
  },
});

const gridData = [
  
  {
    imageSrc: "/Innovation/innovation-1.png",
    imageAlt: "Empower",
    title: "innovation-empower-heading",
    description:"innovation-empower-description",
    dividerHeight: { md: "528px", xs: "2px" },
    dividerWidth: { xs: "50vw", sm: "50vw", md: "2px" }
  },
  {
    imageSrc: "/Innovation/innovation-2.png",
    imageAlt: "Bring out the Innovator Inside",
    title: "innovation-innovator-heading",
    description:"innovation-innovator-description",
    dividerHeight: { md: "528px", xs: "2px" },
    dividerWidth: { xs: "50vw", sm: "50vw", md: "2px" },
    reverse: true
  },
  {
    imageSrc: "/Innovation/innovation-3.png",
    imageAlt: "Access to comprehensive lessons",
    title: "innovation-lesson-heading",
    description:"innovation-lesson-description",
    dividerHeight: { md: "528px", xs: "2px" },
    dividerWidth: { xs: "50vw", sm: "50vw", md: "2px" }
  }
];

function GridItem({
  imageSrc,
  imageAlt,
  title,
  description,
  dividerHeight,
  dividerWidth,
  reverse
  
}) 
{
  return (

    <Grid
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Card
        sx={{
          display: "flex",
          flexDirection: reverse
            ? { xs: "column-reverse", sm: "column-reverse", md: "row" }
            : { xs: "column", sm: "column", md: "row" },
          alignItems: "center",
          columnGap: "100px",
          rowGap: "30px",
          border: "none",
          boxShadow: "none"
        }}
      >
        {reverse ? (
          <>
            <CardContent>
              <Typography variant="h4" gutterBottom sx={{ fontWeight: "700" }}>
                {title}
              </Typography>
              <Typography
                variant="h6"
                sx={{ width: { md: "325px", xs: "65vw" } }}
              >
                {description}
              </Typography>
            </CardContent>
            <Divider
              sx={{
                height: dividerHeight,
                width: dividerWidth,
                background: "#1C94CF"
              }}
            />
            <CardMedia
              component="img"
              src={imageSrc}
              title={imageAlt}
              sx={{ width: { md: "621px", xs: "50vw" } }}
            />
          </>
        ) : (
          <>
            <CardMedia
              component="img"
              src={imageSrc}
              title={imageAlt}
              sx={{ width: { md: "511px", xs: "50vw" } }}
            />
            <Divider
              sx={{
                height: dividerHeight,
                width: dividerWidth,
                background: "#1C94CF"
              }}
            />
            <CardContent>
              <Typography variant="h4" gutterBottom sx={{ fontWeight: "700" }}>
                {title}
              </Typography>
              <Typography
                variant="h6"
                sx={{ width: { md: "370px", xs: "65vw" } }}
              >
                {description}
              </Typography>
            </CardContent>
          </>
        )}
      </Card>
    </Grid>
  );
}

function Innovation() {
  const { t } = useTranslation();

  const translatedGridData = gridData.map((item) => ({
    ...item,
    title: t(item.title),
    description: t(item.description)
  }));

  return (
    <ThemeProvider theme={theme}>
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "128px"
      }}
      maxWidth={false}
      
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: "700",
          textAlign: { md: "center", xs: "left" },
          paddingLeft: { md: "0", xs: "0" },
          width: { xs: "70vw", md: "100vw" }
        }}
      >
         {t('innovation-hero-section')}
      </Typography>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: "50px",
          marginTop: "210px"
        }}
      >
         {translatedGridData.map((item, index) => (
            <GridItem
              key={index}
              imageSrc={item.imageSrc}
              imageAlt={item.imageAlt}
              title={item.title}
              description={item.description}
              dividerHeight={item.dividerHeight}
              dividerWidth={item.dividerWidth}
              reverse={item.reverse}
            />
          ))}
      </Grid>
    </Container>
    </ThemeProvider>
  );
}

export default Innovation;
