import React from 'react'
import PreOrder from '../../Components/PreOrder/PreOrder'

function Preorder() {
  return (
    <>
      <PreOrder />
    </>
  )
}

export default PreOrder