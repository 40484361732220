import React from 'react'
import './LoadingAnimation.css'

function LoadingAnimation() {
  return (
    <div className="loading-container">
      <div className="loading-circle" />
      <div className="loading-circle" />
      <div className="loading-circle" />
    </div>
  )
}

export default LoadingAnimation;
