import React from "react";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import "./Footer.css";
import { useTranslation } from "react-i18next";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function Footer() {
  const { t } = useTranslation();

  const location = useLocation();

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Check if the current route should exclude the footer
  const shouldExcludeFooter =
    location.pathname === "/login/" ||
    location.pathname === "/signup" ||
    location.pathname === "/user" ||
    location.pathname === "/getarobot";

  // Render null if the footer should be excluded
  if (shouldExcludeFooter) {
    return null;
  }

  if (!isMobile) {
    return (
      <div className="footer-container">
        <div className="footer-parent">
          <div className="autobonics-address">
            <div className="footer-bonics-logo-container">
              <img
                className="footer-bonics-logo"
                src="/Footer/AofAutobonics.png"
                alt="Autobonics"
              />
              <img
                className="utobonics-footer"
                src="/Footer/Autobonics.svg"
                alt=" utononics footer"
              />
            </div>
            <div className="autobonics-address-text">
              <p>
              {t("footer-address-text")}
              </p>
            </div>
          </div>

          <div className="autobonics-product-container">
            <h3>{t("footer-product-heading")}</h3>
            <a className="footer-links" href="/get-started">
              {t("footer-getstarted-link")}
            </a>
            <a className="footer-links" href="/projects">
              {t("footer-projects-link")}
            </a>
            <a className="footer-links" href="/about-us">
              {t("footer-aboutus-link")}
            </a>
            <a className="footer-links" href="/preorder">
              {t("footer-preorder-link")}
            </a>
          </div>

          <div className="autobonics-company-container">
            <h3> {t("footer-company-heading")}</h3>
            <div className="autobonics-company-container-group">
              <a className="footer-links" href="/about-us">
                {t("footer-aboutus-link")}
              </a>
              <a className="footer-links" href="/careers">
                {t("footer-carrer-link")}
              </a>
              <a className="footer-links" href="/faq">
                {t("footer-faq-link")}
              </a>

              <a className="footer-links" href="/ContactUs">
                {t("footer-contact-link")}
              </a>
            </div>
          </div>

          <div className="autobonics-help-container">
            <h3>{t("footer-gethelp-heading")}</h3>
            <div className="autobonics-company-help-group">
              <a className="footer-links" href="/get-started">
              {t("footer-privacy-link")}
              </a>
              <a className="footer-links" href="/projects">
              {t("footer-terms-link")}
              </a>
              <a className="footer-links" href="/about-us">
              {t("footer-legal-link")}
              </a>
              <a className="footer-links" href="/preorder">
              {t("footer-delivery-link")}
              </a>
            </div>
          </div>

          <div class="autobonics-family-container">
            <p>{t("footer-followus-link")}</p>
            <div className="socialmedia-icons">
              <img
                src="/Footer/facebook.svg"
                alt="facebook"
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/people/Bonicai/100090301741547/",
                    "_blank"
                  )
                }
                style={{ cursor: "pointer" }}
              />
              <img
                src="/Footer/instagram.svg"
                alt="instagram"
                onClick={() =>
                  window.open("https://www.instagram.com/bonic.ai/", "_blank")
                }
                style={{ cursor: "pointer" }}
              />
              <img
                src="/Footer/twitter.svg"
                alt="twitter"
                onClick={() =>
                  window.open("https://twitter.com/bonic4all", "_blank")
                }
                style={{ cursor: "pointer" }}
              />
              <img
                src="/Footer/yt.svg"
                alt="YouTube"
                onClick={() =>
                  window.open(
                    "https://www.youtube.com/@bonic4all/featured",
                    "_blank"
                  )
                }
                style={{ cursor: "pointer" }}
              />
              <img src="/Footer/linkedin.svg" alt="LinkedIn" />
            </div>
          </div>
        </div>
        <div className="footer-policy">
          <span className="footer-rights">{t("rights")}</span>
        </div>
      </div>
    );
  }

  return (
    <div className="footer-container">
      <div className="footer-parent center">
        <Accordion>
          <AccordionSummary expandIcon={<AddIcon />}>
            <Typography
              variant="h5"
              style={{ textAlign: "center", width: "100%" }}
            >
              {t("footer-product-heading")}
            </Typography>
          </AccordionSummary>

          <AccordionDetails style={{ textAlign: "center", width: "100%", border:"none" }}>
            <div className="autobonics-product-container">
              <a className="footer-links" href="/get-started">
                {t("footer-getstarted-link")}
              </a>
              <a className="footer-links" href="/projects">
                {t("footer-projects-link")}
              </a>
              <a className="footer-links" href="/about-us">
                {t("footer-aboutus-link")}
              </a>
              <a className="footer-links" href="/preorder">
                {t("footer-preorder-link")}
              </a>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<AddIcon />}>
            <Typography
              variant="h5"
              style={{ textAlign: "center", width: "100%" }}
            >
              {t("footer-company-heading")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="autobonics-company-container">
              <a className="footer-links" href="/about-us">
              {t("footer-aboutus-link")}
              </a>
              <a className="footer-links" href="/careers">
                {t("footer-carrer-link")}
              </a>
              <a className="footer-links" href="/faq">
                {t("footer-faq-link")}
              </a>
              <a className="footer-links" href="/ContactUs">
                {t("footer-contact-link")}
              </a>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<AddIcon />}>
            <Typography
              variant="h5"
              style={{ textAlign: "center", width: "100%" }}
            >
              {t("footer-gethelp-heading")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="autobonics-help-container">
              <a className="footer-links" href="/get-started">
              {t("footer-privacy-link")}
              </a>
              <a className="footer-links" href="/projects">
              {t("footer-terms-link")}
              </a>
              <a className="footer-links" href="/about-us">
              {t("footer-legal-link")}
              </a>
              <a className="footer-links" href="/preorder">
              {t("footer-delivery-link")}
              </a>
            </div>
          </AccordionDetails>
        </Accordion>
        <div className="autobonics-address">
          <div className="footer-bonics-logo-container">
            <img
              className="footer-bonics-logo"
              src="/Footer/AofAutobonics.png"
              alt="Autobonics"
            />
            <img
              className="utobonics-footer"
              src="/Footer/utobonics.png"
              alt=" utononics footer"
            />
          </div>
          <div className="autobonics-address-text">
            <p>
              {t("address1")} <br />
              {t("address2")}
              <br />
              {t("address3")}
            </p>
          </div>
          <div className="autobonics-rights-mobile">
            <p>© 2023 All Rights Reserved</p>
          </div>
        </div>

        <div class="autobonics-family-container">
          <p>{t("footer-followus-link")}</p>
          <div className="socialmedia-icons">
            <img
              src="/Footer/facebook.svg"
              alt="facebook"
              onClick={() =>
                window.open(
                  "https://www.facebook.com/people/Bonicai/100090301741547/",
                  "_blank"
                )
              }
              style={{ cursor: "pointer" }}
            />
            <img
              src="/Footer/instagram.svg"
              alt="instagram"
              onClick={() =>
                window.open("https://www.instagram.com/bonic.ai/", "_blank")
              }
              style={{ cursor: "pointer" }}
            />
            <img
              src="/Footer/twitter.svg"
              alt="twitter"
              onClick={() =>
                window.open("https://twitter.com/bonic4all", "_blank")
              }
              style={{ cursor: "pointer" }}
            />
            <img
              src="/Footer/yt.svg"
              alt="YouTube"
              onClick={() =>
                window.open(
                  "https://www.youtube.com/@bonic4all/featured",
                  "_blank"
                )
              }
              style={{ cursor: "pointer" }}
            />
            <img src="/Footer/linkedin.svg" alt="LinkedIn" />
          </div>
        </div>
      </div>
      <div className="footer-policy">
        {/* <div className="footer-policy-first">
        <p>{t("policy")}</p>
        <p>{t("terms")}</p>
        <p>{t("leg")}</p>
        <p>{t("delivery")}</p>{" "}
      </div> */}
        <span className="footer-rights">{t("rights")}</span>
      </div>
    </div>
  );
}