
import React, { useState, useEffect, useRef } from "react";
import "./Projects.css";
import { Link, useHistory } from "react-router-dom";
import { setDoc, doc } from "firebase/firestore";
import GetaRoboPopup from "../GetaRoboPopup/GetaRoboPopup";
import { useTranslation } from "react-i18next";
import { collection, query, getDocs, orderBy } from "firebase/firestore";
import { db } from "../../Firebase/config.js";
import { FiChevronRight } from "react-icons/fi";

function Projects() {
  const [showPopup, setShowPopup] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [Project, setProjects] = useState([]);
  const [imageLoaded, setImageLoaded] = useState(false);

  const [selectedLevel, setSelectedLevel] = useState(() => {
    const storedSelectedLevel = localStorage.getItem("selectedLevel");
    return storedSelectedLevel || "beginner";
  });

  const [cardScrollPositions, setCardScrollPositions] = useState({});

  const history = useHistory();
  const { t } = useTranslation();

  const scrollPositionsRef = useRef({});


  const handleScroll = (cardId) => {
    return (event) => {
      const { scrollTop } = event.target;
      setCardScrollPositions((prevState) => ({
        ...prevState,
        [cardId]: scrollTop,
      }));
    };
  };

  useEffect(() => {
    const storedSelectedLevel = localStorage.getItem("selectedLevel");
    if (storedSelectedLevel) {
      setSelectedLevel(storedSelectedLevel);
    }
  }, []);

  useEffect(() => {
    getVideos(selectedLevel);
    localStorage.setItem("selectedLevel", selectedLevel);
  }, [selectedLevel]);

  async function getVideos(currentSelection) {
    const q = query(
      collection(db, `project_categories/${currentSelection}/projects`),
      orderBy("index")
    );
    const querySnapshot = await getDocs(q);
    let temp = [];
    querySnapshot.forEach((doc) => {
      temp.push(doc.data());
    });
    setProjects(temp);
  }

  const handleButtonClick = () => {
    const mediaQuery = window.matchMedia("(max-width: 767px)");
    if (mediaQuery.matches) {
      history.push("/getarobot");
    } else {
      setShowPopup(true);
    }
  };

  if (showPopup) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "";
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    const nameInput = event.target.name;
    const emailInput = event.target.email;

    if (!nameInput || !emailInput) {
      console.error("Name or email input is missing");
      return;
    }

    const name = nameInput.value;
    const email = emailInput.value;

    // Validate name input
    if (!name.trim()) {
      nameInput.style.border = "2px solid red";
      return;
    } else {
      nameInput.style.border = "2px solid green";
    }

    // Validate email input
    if (!email.trim() || !/\S+@\S+\.\S+/.test(email)) {
      emailInput.style.border = "2px solid red";
      return;
    } else {
      emailInput.style.border = "2px solid green";
    }

    try {
      const ref = doc(db, "leads", email);
      await setDoc(ref, {
        name: name,
        email: email,
        timestamp: new Date()
      });
      console.log("Data saved successfully");
    } catch (error) {
      console.error("Error saving data: ", error);
    }

    setName("");
    setEmail("");
    setShowPopup(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const handleSelectedButton = (level) => {
    setSelectedLevel(level);
    localStorage.setItem("selectedLevel", level);
  };

  useEffect(() => {
    const cardElements = document.querySelectorAll(".card");
    cardElements.forEach((card) => {
      card.scrollTop = scrollPositionsRef.current[card.id] || 0;
    });

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="projects-largerecreen-parent">
      {showPopup && (
        <div className="popup-overlay" onClick={handlePopupClose} />
      )}
      <div className={`projects-parent${showPopup ? " popup-active" : ""}`}>
        {/* <div className="projects-content-heading-container"> */}
        <div className="projects-heading-mobile-para">
          <h2 className="projects-heading">{t("projects-hero-section")}</h2>
        </div>

        <div className="characterandheading">
          <div className="characterdesk">
            <img
              className="characterdeskimg"
              src="/Projects/drawing.svg"
              alt=""
            />
          </div>
          <div className="projects-side-heading">
            <div className="projects-side-main">
             <p>
             {t("projects-hero-sub")}
             </p>
            </div>
            <div className="projects-side-secondary">
              <p>
              {t("projects-sub-desc")}
              </p>
            </div>
          </div>
        </div>
        {/* </div> */}
        {showPopup && <GetaRoboPopup onClose={handlePopupClose} />}
        <div class="project-level-button-container">
        <button
    className={selectedLevel === "beginner" ? "selected" : ""}
    onClick={() => handleSelectedButton("beginner")}
    style={{
      display: "flex",
      width: window.innerWidth <= 900 ? "17.06vw" : "178px",
      maxWidth: "178px",
      height: window.innerWidth <= 900 ? "4.6vw" : "48px",
      padding: 0,
      margin: "0 0.5rem 0.5rem 0",
      border: "none",
      borderRadius: "10.2px",
      backgroundColor: selectedLevel === "beginner" ? "#222" : "#B9BFC0",
      color: "white",
      fontSize: window.innerWidth <= 900 ? "1.51vw" : "16px",
      alignItems: "center",
      justifyContent: "center",
      lineHeight: 1,
      textAlign: "center",
      transition: "background-color 0.3s ease"
    }}
  >
    <i
      className="arrow fas fa-chevron-right"
      style={{
        transform: selectedLevel === "beginner" ? "rotate(90deg)" : "",
        color: selectedLevel === "beginner" ? "rgba(40, 131, 172, 1)" : ""
      }}
    ></i>
    <span>{t("projects-beginner-btn")}</span>
  </button>

  <button
    className={selectedLevel === "intermediate" ? "selected" : ""}
    onClick={() => handleSelectedButton("intermediate")}
    style={{
      display: "flex",
      width: window.innerWidth <= 900 ? "17.06vw" : "178px",
      maxWidth: "178px",
      height: window.innerWidth <= 900 ? "4.6vw" : "48px",
      padding: 0,
      margin: "0 0.5rem 0.5rem 0",
      border: "none",
      borderRadius: "10.2px",
      backgroundColor: selectedLevel === "intermediate" ? "#222" : "#B9BFC0",
      color: "white",
      fontSize: window.innerWidth <= 900 ? "1.51vw" : "16px",
      alignItems: "center",
      justifyContent: "center",
      lineHeight: 1,
      textAlign: "center",
      transition: "background-color 0.3s ease"
    }}
  >
    <i
      className="arrow fas fa-chevron-right"
      style={{
        transform: selectedLevel === "intermediate" ? "rotate(90deg)" : "",
        color: selectedLevel === "intermediate" ? "rgba(40, 131, 172, 1)" : ""
      }}
    ></i>
    <span>{t("projects-intermediate-btn")}</span>
  </button>

  <button
    className={selectedLevel === "advanced" ? "selected" : ""}
    onClick={() => handleSelectedButton("advanced")}
    style={{
      display: "flex",
      width: window.innerWidth <= 900 ? "17.06vw" : "178px",
      maxWidth: "178px",
      height: window.innerWidth <= 900 ? "4.6vw" : "48px",
      padding: 0,
      margin: "0 0.5rem 0.5rem 0",
      border: "none",
      borderRadius: "10.2px",
      backgroundColor: selectedLevel === "advanced" ? "#222" : "#B9BFC0",
      color: "white",
      fontSize: window.innerWidth <= 900 ? "1.51vw" : "16px",
      alignItems: "center",
      justifyContent: "center",
      lineHeight: 1,
      textAlign: "center",
      transition: "background-color 0.3s ease"
    }}
  >
    <i
      className="arrow fas fa-chevron-right"
      style={{
        transform: selectedLevel === "advanced" ? "rotate(90deg)" : "",
        color: selectedLevel === "advanced" ? "rgba(40, 131, 172, 1)" : ""
      }}
    ></i>
    <span>{t("projects-advanced-btn")}</span>
  </button>
        </div>

        <div className="card-grid">
          {Project !== null &&
            Project.map((project) => (
               <div
              className={`card ${cardScrollPositions[project.id] !== undefined ? 'viewed' : ''}`}
              id={project.id}
              key={project.index}
            >
                <Link
                  to={`/projects/${selectedLevel}:${project.title
                    .trim()
                    .toLowerCase()
                    .replaceAll(" ", "_")}`}
                  className="card-link"
                >
                  <div className="image-container-inside-card">
                    <div className="level-text-container">
                      <p className="level-text">
                        {t("level")}
                        {project.index}
                      </p>
                    </div>
                    <img
                      src={project.imageUrl || "/Projects/robotarm.png"}
                      alt=""
                    />
                  </div>
                  <div className="projects-name-container">
                    <div className="tickle-text-container">
                      <p className="tickle-text">{project.title}</p>
                      {project.steps.length > 0 ? (
                        <button className="explore-btn-projects">
                          <FiChevronRight />
                        </button>
                      ) : (
                        <button className="explore-btn-projects">
                          <FiChevronRight />
                        </button>
                      )}
                    </div>
                    <p className="steps-text">
                      {project.description.length > 0 && (
                        <>{project.description}</>
                      )}
                    </p>
                  </div>
                </Link>
              </div>
            ))}
        </div>

        {/* <div className="makerobot-container">
          <p>
            {t("makerobotext1")}
            <br />
            {t("makerobotext2")}
          </p>
          <button onClick={handleButtonClick}>{t("makerobobutton")}</button>
        </div>
        <div className="makerobot-container-mobile-parent">
          <div className="makerobot-container-mobile">
            <p>
              {t("makerobotext1")}
              <br />
              {t("makerobotext2")}
            </p>
            <button onClick={handleButtonClick}>{t("makerobobutton")}</button>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Projects;