import React, { useState, useEffect, useMemo, useRef } from "react";
import "./Header.css";
import { Link, NavLink, useLocation } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Header({
  handleLanguageSelect,
  showLanguageSelection,
  setShowLanguageSelection
}) {
  const [showLinks, setShowLinks] = useState(false);
  const { user } = useAuthContext();
  const location = useLocation();
  const history = useHistory();
  const linksRef = useRef(null);
 


  const { t, i18n } = useTranslation();

  const selectedLanguageText = i18n.language === "en" ? "عربي" : "English";

  const toggleLanguageSelection = () => {
    setShowLanguageSelection(!showLanguageSelection);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (linksRef.current && !linksRef.current.contains(event.target)) {
        setShowLinks(false);
      }
    };

    

    const handleEscapeKey = (event) => {
      if (event.key === "Escape") {
        setShowLinks(false);
      }
    };

    if (showLinks) {
      document.body.classList.add("no-scroll");
      document.addEventListener("click", handleClickOutside);
      document.addEventListener("keydown", handleEscapeKey);
    } else {
      document.body.classList.remove("no-scroll");
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
    }

    return () => {
      document.body.classList.remove("no-scroll");
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [showLinks]);

  useEffect(() => {
    setShowLinks(false); 
  }, [location]);


  const handleHamburgerClick = () => {
    setShowLinks(!showLinks);
  };

  const logo = useMemo(
    () => (
      <img
        className="bonics-logo"
        src="/Images/bonicsLogo.png"
        alt="Bonic Logo"
        style={{ borderBottom: "none" }}
      />
    ),
    []
  );

  return (
    <div className="parent-header-main-container">
      <div className="parent-header">
        <div className="bonic-logo-container-header">
          <NavLink
            exact
            to="/"
            className="header-link nav-link"
            activeClassName="active"
            style={{ borderBottom: "none" }}
          >
            {logo}
          </NavLink>

          <NavLink
            exact
            to="/"
            className="header-link nav-link"
            activeClassName="active"
            style={{ borderBottom: "none" }}
          >
            <img
              className="bonic-text"
              src="/Images/bonicText.png"
              alt="Bonic"
              style={{ borderBottom: "none" }}
            />
          </NavLink>
        </div>
        <div className="hamburger-menu" onClick={handleHamburgerClick}>
          <div className="hamburger-line"></div>
          <div className="hamburger-line"></div>
          <div className="hamburger-line"></div>
        </div>
        <div
          className={`overlay ${showLinks ? "show" : ""}`}
          onClick={() => setShowLinks(false)}
        ></div>
        <div className={`header-links ${showLinks ? "show" : ""}`}>
          <button
            className="close-button-hamburger-menu"
            onClick={() => setShowLinks(false)}
          >
            <i className="fas fa-times"></i>
          </button>
          <NavLink
            exact
            to="/"
            className="header-link nav-link"
            activeClassName="active"
          >
            {t("header-home-link")}
          </NavLink>
          <NavLink
            to="/get-started"
            className="header-link nav-link"
            activeClassName="active"
          >
            {t("header-getstarted-link")}
          </NavLink>
          <NavLink
            to="/projects"
            className="header-link nav-link"
            activeClassName="active"
          >
            {t("header-projects-link")}
          </NavLink>
          <NavLink
            to="/about-us"
            className="header-link nav-link"
            activeClassName="active"
          >
            {t("header-aboutus-link")}
          </NavLink>
          <NavLink
            to="/codeit"
            className="header-link nav-link"
            activeClassName="active"
          >
            {t("header-codeit-link")}
          </NavLink>
          <div className="language-selection-desktop">
        <p
          onClick={() => handleLanguageSelect(i18n.language === "en" ? "ar" : "en")}
          style={{
            color: "#096EB6",
            cursor: "pointer",
            marginTop:"0"
          }}
        >
          {selectedLanguageText}
        </p>
      </div>

          <div className="language-selection-mobile">
            <h3>Choose Language:</h3>
            <div className="language-selection-dropdown">
              <p
                onClick={() => handleLanguageSelect("en")}
                style={{
                  color: i18n.language === "en" ? "#096EB6" : "#A59F9F"
                }}
              >
                English
              </p>
              <p
                onClick={() => handleLanguageSelect("ar")}
                style={{
                  color: i18n.language === "ar" ? "#096EB6" : "#A59F9F"
                }}
              >
                عربي
              </p>
            </div>
          </div>
        </div>

        <div class="mobile-logon-preorder-container">
          {user && (
            <div
              class="mobile-logon-preorder-container-login"
              style={{ backgroundColor: "#096EB6", borderRadius: "20px" }}
            >
              <div
                class="user-initial-circle"
                onClick={() => history.push("/user")}
              >
                <h3
                  style={{
                    color: "#FFFFFF",
                    margin: 0,
                    marginInlineStart: "9px",
                    lineHeight: "30px",
                    textAlign: "center"
                  }}
                >
                  {user.name ? user.name[0].toUpperCase() : "A"}
                </h3>
              </div>
            </div>
          )}
          {!user && (
            <a href="/login">
              <div class="mobile-logon-preorder-container-login">
                <i class="fas fa-user-circle fa-bounce"></i>
                <a>{t("log")}</a>
              </div>
            </a>
          )}

          <a href="/preorder">
            <div
              class="mobile-logon-preorder-container-preorder"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <i class="fas fa-shopping-cart fa-bounce"></i>
              <a> {t("header-preorder-link")} </a>
            </div>
          </a>
        </div>

        <div className="login-preorder-container">
          {!user && (
            <a
              href="login"
              className="login-link-header"
              style={{ color: "#096EB6" }}
              // onClick={handleLoginClick}
            >
              login
            </a>
          )}
          {user && (
            <div
              className="user-initial-circle"
              style={{
                backgroundColor: /^\/user\/?$/.test(location.pathname)
                  ? " #034FB4"
                  : "#D1D7DF",
                display: "flex",
                width: "51px",
                height: "51px",
                borderRadius: "50%",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                lineHeight: "30px",
                color: "#FFFFFF",
                margin: "0 10px",
                marginLeft: "-35px",
                marginTop: "-16px",
                cursor: "pointer"
              }}
              onClick={() => history.push("/user")}
            >
              <h3 style={{ margin: "0", fontSize: "16px" }}>
                {user.name ? user.name[0].toUpperCase() : "A"}
              </h3>
            </div>
          )}

          <NavLink
            to="/preorder"
            className="preorder-header-element-link"
            // activeClassName="active"
            style={{ borderBottom: "none", marginTop: "-22px" }}
          >
            {t("header-preorder-link")}
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default Header;
