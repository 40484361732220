import React from "react";
import "./GetaRobotMobile.css";
import { useState } from "react";
import { db } from "../../Firebase/config.js";
import { setDoc, doc } from "firebase/firestore";

import { useTranslation } from 'react-i18next';


function GetaRobotMobile() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");


  const { t } = useTranslation();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const nameInput = event.target.name;
    const emailInput = event.target.email;

    if (!nameInput || !emailInput) {
      console.error("Name or email input is missing");
      return;
    }

    const name = nameInput.value;
    const email = emailInput.value;
    // Validate name input
    if (!name.trim()) {
      nameInput.style.border = "2px solid red";
      return;
    } else {
      nameInput.style.border = "2px solid green";
    }

    // Validate email input
    if (!email.trim() || !/\S+@\S+\.\S+/.test(email)) {
      emailInput.style.border = "2px solid red";
      return;
    } else {
      emailInput.style.border = "2px solid green";
    }

    try {
      const ref = doc(db, "leads", email);
      await setDoc(ref, {
        name: name,
        email: email,
        timestamp: new Date()
      });
      console.log("Data saved successfully");
    } catch (error) {
      console.error("Error saving data: ", error);
    }

    setName("");
    setEmail("");
  };
  return (
    <div className="getrobo-container-mobile -parent">
      <div className="getrobo-container-mobile    ">
        <div className="getrobo-container-mobile-top">
          <div className="popup-container-top-heading">
            <h2 style={{ fontWeight: "bolder" }}>
            {t('getrobo-main-heading')}
            </h2>
            <p>
            {t('getrobo-sub-heading')}
            </p>
          </div>
        </div>
        <div className="getrobo-container-mobile-bottom">
          <div className="popup-input-image-container">
            <img
              className="popup-input-image"
              src="/Home/smallkidrobo.png"
              alt=""
            />
          </div>
          <div className="popoup-input-container">
            <form className="popup-form" onSubmit={handleSubmit}>
              <label
                htmlFor=""
                style={{
                  paddingLeft: "50px",
                  marginBottom: "5px",
                  color: "#004DB1",
                  fontSize: "16px",
                  fontWeight: "bolder"
                }}
              >
               {t('getrobo-name')}
              </label>
              <input
                type="text"
                name="name" 
                placeholder= {t('getrobo-name')}
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={{ paddingLeft: "30px" }}
              />
              <label
                htmlFor=""
                style={{
                  paddingLeft: "50px",
                  marginBottom: "5px",
                  color: "#004DB1",
                  fontSize: "16px",
                  fontWeight: "bolder"
                }}
              >
                {t('getrobo-email')}
              </label>
              <input
                type="email"
                name="email" 
                placeholder={t('getrobo-email')}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ paddingLeft: "30px" }}
              />

              <button type="submit">{t('getrobo-getfies-desc')}</button>
            </form>
          </div>
          <div className="getarobo-placeholder-div">

          </div>
        </div>
      </div>
    </div>
  );
}

export default GetaRobotMobile;
