import React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { db } from "../../Firebase/config.js";
import { useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { setDoc, doc } from "firebase/firestore";
import GetaRoboPopup from "../../Components/GetaRoboPopup/GetaRoboPopup.js";
import Button from "@mui/material/Button";
import "./Introduction.css"

function Introduction() {

    const { t } = useTranslation();

    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 1100,
            lg: 1300,
            xl: 1536
          }
        }
      });
    
      const [showPopup, setShowPopup] = React.useState(false);
      const [modalIsOpen, setModalIsOpen] = useState(false);
    
      const [name, setName] = useState("");
      const [email, setEmail] = useState("");
    
      const history = useHistory();
    
    
      const isSmallScreen = useMediaQuery("(max-width: 1100px)");
    
      const handleButtonClick = () => {
        const mediaQuery = window.matchMedia("(max-width: 767px)");
        if (mediaQuery.matches) {
          history.push("/getarobot");
        } else {
          setShowPopup(true);
        }
      };
      const openModalButton = () => {
        setModalIsOpen(true);
      };
    
      const handleSubmit = async (event) => {
        event.preventDefault();
    
        const nameInput = event.target.name;
        const emailInput = event.target.email;
    
        if (!nameInput || !emailInput) {
          console.error("Name or email input is missing");
          return;
        }
    
        const name = nameInput.value;
        const email = emailInput.value;
        // Validate name input
        if (!name.trim()) {
          nameInput.style.border = "2px solid red";
          return;
        } else {
          nameInput.style.border = "2px solid green";
        }
    
        // Validate email input
        if (!email.trim() || !/\S+@\S+\.\S+/.test(email)) {
          emailInput.style.border = "2px solid red";
          return;
        } else {
          emailInput.style.border = "2px solid green";
        }
    
        try {
          const ref = doc(db, "leads", email);
          await setDoc(ref, {
            name: name,
            email: email,
            timestamp: new Date()
          });
          console.log("Data saved successfully");
        } catch (error) {
          console.error("Error saving data: ", error);
        }
    
        setName("");
        setEmail("");
        setShowPopup(false);
      };
      const handlePopupClose = () => {
        setShowPopup(false);
      };
      useEffect(() => {
        const body = document.querySelector("body");
        if (modalIsOpen) {
          body.style.overflow = "hidden";
        } else {
          body.style.overflow = "visible";
        }
      }, [modalIsOpen]);
    
      const handleOrderClick = () => {
        history.push("/preorder");
      };
    

  return (
    <>
    {showPopup && (
        <div className="popup-overlay" onClick={handlePopupClose} />
      )}
      <div style={{ background: "url(/Home/doodle-bonic-1.png)" }}>
        <Container
          maxWidth={"lg"}
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center"
          }}
        >
          <Modal
            open={modalIsOpen}
            onClose={() => setModalIsOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ padding: "2vw" }}
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "95vw",
                bgcolor: "background.paper",
                border: "none",
                boxShadow: 24,
                p: 0, // Remove padding
                paddingTop: "56.25%", // 16:9 ratio (9/16 * 100%)
                overflow: "hidden"
              }}
            >
              <iframe
                src="https://www.youtube.com/embed/cZ62N3z3BoI"
                title="YouTube Video"
                allowFullScreen
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%"
                }}
              />
            </Box>
          </Modal>

          <Typography
            variant="h2"
            component="h1"
            color="black"
            sx={{
              fontWeight: "700",
              textAlign: "center",
              lineHeight: "normal",
              paddingTop: { xs: "50px" }
            }}
          >
            {t("introduction-hero-section")}
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              columnGap: "46px",
              marginTop: "100px"
            }}
          >
            <Button
              sx={{
                minWidth: { xs: "33vw", md: "161px" },
                borderRadius: "12px",
                background: "#1C94CF",
                color: "white",
                boxShadow:
                  "0px 1.3966480493545532px 6.983240127563477px 0px rgba(101, 192, 237, 0.50)"
              }}
              variant="contained"
              onClick={handleButtonClick}
            >
              {t("intoduction-free-btn")}
            </Button>
            <Button
              sx={{
                minWidth: { xs: "33vw", md: "161px" },
                borderRadius: "12px",
                border: "2px solid #1C94CF",
                color: "black",
                fontWeight: "700",
                boxShadow:
                  "0px 1.3966480493545532px 6.983240127563477px 0px rgba(101, 192, 237, 0.50)"
              }}
              variant="outlined"
              onClick={handleOrderClick}
            >
              {t("introduction-order-btn")}
            </Button>
          </Box>
          <ThemeProvider theme={theme}>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", xm: "column", md: "row" },
                marginTop: "200px",
                rowGap: "67px",
                width: { xs: "335px", xm: "600px", md: "1200px" },
                marginBottom: "200px",
                alignItems: "center",
                justifyContent: { md: "space-evenly", xs: "center" }
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "45px",
                  alignItems: { md: "baseline", xs: "center" },
                  justifyContent: { xs: "center", md: "flex-start" }
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    color: "black",
                    textAlign: "center",
                    fontWeight: "700"
                  }}
                >
                  {t("bonic-intro-sub-heading")}
                </Typography>
                <Typography
                  sx={{
                    color: "black",
                    fontWeight: "400",
                    textAlign: { md: "justify", xs: "center" },
                    width: { md: "450px", xs: "93vw" }
                  }}
                  variant="h6"
                >
                 {t("bonic-intro-sub-definition")}
                </Typography>
              </Box>

              <div className="bonic-intro-divider" />
              <div className="bonic-intro-divider-mobile" />

              <Box
                component="img"
                sx={{
                  width: { xs: "90vw", sm: "90vw", md: "481px" },
                  objectFit: "cover"
                }}
                alt="Student learning graphics"
                src={process.env.PUBLIC_URL + "/Home/but-why-1.png"}
                onClick={openModalButton}
              />
            </Box>
          </ThemeProvider>
          {showPopup && <GetaRoboPopup onClose={handlePopupClose} />}
        </Container>
      </div>
      </>
  )
}

export default Introduction