import { useState, useEffect } from "react";
import { auth } from "../Firebase/config";
import { signInWithPopup, GoogleAuthProvider, signInWithEmailAndPassword } from "firebase/auth";
import { useAuthContext } from "./useAuthContext";


// Google auth proivider
const provider = new GoogleAuthProvider();

export const useLogin = () => {
  const [isCancelled, setIsCancelled] = useState(false);
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const { dispatch } = useAuthContext();

  const login = async (email, password) => {
    setError(null);
    setIsPending(true);

    try {
      let res;
      if(email && password){
        res = await signInWithEmailAndPassword(auth, email, password);
      } else {
      // login with google
        res = await signInWithPopup(auth, provider);
      }


      if (!res || !res.user) {
        if (!isCancelled) {
          setIsPending(false);
          setError("Login failed");
        }
        throw new Error("Could not complete login");
      }

      dispatch({ type: "LOGIN", payload: res.user });

      if (!isCancelled) {
        setIsPending(false);
        setError(null);
      }
    } catch (err) {
      console.log(err.message);
      // if (!isCancelled) {
        setError(err.message);
        setIsPending(false);
      // }
    }
  };

  useEffect(() => {
    return () => setIsCancelled(true);
  }, []);

  return { login, isPending, error };
};
