import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';


import {
  Box,
  Button,
  Card,
  Container,
  Typography,
  Hidden,
  createTheme,
  ThemeProvider
} from "@mui/material";
import { width } from "@mui/system";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1200,
      lg: 1200,
      xl: 1836,
    },
  },
});

function Products() {

  const { t } = useTranslation();

  const history = useHistory();

  const handleknowmoreClick = () => {
    history.push("/preorder");
  };

  return (
    <ThemeProvider theme={theme}>
    <Container maxWidth={"lg"} disableGutters>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          marginLeft: "20px",
          marginTop: "110px"
        }}
      >
        <Typography
          variant="h5"
          sx={{
            color: "rgba(28, 148, 207, 1)",
            fontWeight: "800",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            columnGap: "25px"
          }}
        >
          <img src="/Features/key.png" alt="" />
          {t('product-side-heading')}
        </Typography>
      </Box>
      <Container
        sx={{
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
          columnGap: "60px",
          rowGap: "60px",
          alignItems: "center",
          marginTop: "130px"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: { md: "680px", xs: "90vw" },
            height: { md: "auto", xs: "auto" },
            paddingBottom: { md: "30px", xs: "60px" },
            borderRadius: { md: "79px", xs: "33px" },
            boxShadow: "0px 4px 15px 10px rgba(0, 0, 0, 0.25)"
          }}
        >
          <img src="/Products/brain.png" alt="" />
          <Typography
            textAlign="center"
            variant="h2"
            component="h2"
            sx={{ color: "#222", fontWeight: "700", marginTop: "-60px" }}
          >
           {t('product-brain-heading')}
          </Typography>
          <Typography
            variant="h4"
            textAlign="center"
            fontWeight="600"
            marginTop="75px"
          >
            {t('product-package-includes')}
          </Typography>
          <Hidden smUp>
            <hr
              style={{
                width: "80vw",
                height: "1px",
                backgroundColor: "#1C94CF"
              }}
            />
          </Hidden>
          <Typography
            variant="h5"
            fontWeight="600"
            marginLeft="30px"
            lineHeight="40px"
            marginTop="20px"
          >
            <ul style={{ listStyleType: "disc" }}>
              <li> {t('product-brain-heading')}</li>
              <li>{t('prduct-classes-point')}</li>
              <li>{t('product-save-share')}</li>
              <li>{t('product-projects')}</li>
              <li>{t('product-battery')}</li>
              <li>
              {t('product-community')}
              </li>
            </ul>
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-evenly",
              marginTop: { md: "160px", xs: "50px" }
            }}
          >
            <Button
              sx={{
                // width: {md:"161px",xs:"30vw"},
                height: "48px",

                fontWeight: "700",
                color: "black",
                borderRadius: "12px",
                border: "2px solid #000",
                boxShadow:
                  "0px 1.3966480493545532px 6.983240127563477px 0px rgba(101, 192, 237, 0.50)"
              }}
              variant="outlined"
              onClick={handleknowmoreClick}
            >
             {t('products-know-more-btn')}
            </Button>
            <Button
              sx={{
                // width: {md:"161px",xs:"30vw"},
                height: "48px",

                fontWeight: "700",
                background: "white",
                color: "black",
                borderRadius: "12px",
                border: "none",
                boxShadow:
                  "0px 1.3966480493545532px 6.983240127563477px 0px rgba(101, 192, 237, 0.50)"
              }}
              variant="contained"
            >
             {t('products-checkout-btn')}
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: { md: "680px", xs: "90vw" },
            height: { md: "auto", xs: "auto" },
            paddingBottom: "30px",
            borderRadius: { md: "79px", xs: "33px" },
            boxShadow: "0px 4px 15px 10px rgba(0, 0, 0, 0.25)"
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "50px"
            }}
          >
            <img
              src="/Products/kit.png"
              style={{ width: "60%", alignContent: "center" }}
              alt=""
            />
          </Box>
          <Typography
            textAlign="center"
            variant="h2"
            sx={{ color: "#222", fontWeight: "700", marginTop: "20px" }}
          >
             {t('product-starter-kit-heading')}
          </Typography>
          <Typography variant="h4" textAlign="center" fontWeight="600">
          {t('product-package-includes')}
          </Typography>
          <Hidden smUp>
            <hr
              style={{
                width: "80vw",
                height: "1px",
                backgroundColor: "#1C94CF"
              }}
            />
          </Hidden>
          <Typography
            variant="h5"
            fontWeight="600"
            marginLeft="30px"
            lineHeight="40px"
            marginTop="20px"
            style={{ listStyleType: "disc" }}
          >
            <ul>
              <li> {t('product-brain-heading')}</li>
              <li>{t('product-classes-point-2')}</li>
              <li>{t('product-bmove')}</li>
              <li>{t('product-brotate')}</li>
              <li>{t('product-bexplore')}</li>
              <li>{t('product-projects')}</li>
              <li>{t('product-battery')}</li>
              <li>{t('product-save-share')}</li>
              <li>{t('product-community')}</li>
            </ul>
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-evenly",
              marginTop: "50px"
            }}
          >
            <Button
              sx={{
                // width: {md:"161px",xs:"30vw"},
                height: "48px",
                fontSize: "16px",
                fontWeight: "700",
                color: "black",
                borderRadius: "12px",
                border: "2px solid #000",
                boxShadow:
                  "0px 1.3966480493545532px 6.983240127563477px 0px rgba(101, 192, 237, 0.50)"
              }}
              variant="outlined"
              onClick={handleknowmoreClick}
            >
             {t('products-know-more-btn')}
            </Button>
            <Button
              sx={{
                // width: {md:"161px",xs:"30vw"},
                height: "48px",
                fontSize: "16px",
                fontWeight: "700",
                background: "white",
                color: "black",
                borderRadius: "12px",
                border: "none",
                boxShadow:
                  "0px 1.3966480493545532px 6.983240127563477px 0px rgba(101, 192, 237, 0.50)"
              }}
              variant="contained"
            >
            {t('products-checkout-btn')}
            </Button>
          </Box>
        </Box>
      </Container>
    </Container>
    </ThemeProvider>
  );
}

export default Products;