    import React from 'react';
    import './LanguageSelectionPopup.css';

    function LanguageSelectionPopup({ onSelect }) {
    const handleLanguageSelect = (language) => {
        onSelect(language);
        localStorage.setItem('language', language);
    };

    return (
        <div className="language-selection-popup">
        <h2>Language</h2>
        <div>
            <button onClick={() => handleLanguageSelect('en')}>English</button>
            <button onClick={() => handleLanguageSelect('ar')}>Arabic</button>
        </div>
        </div>
    );
    }

    export default LanguageSelectionPopup;
