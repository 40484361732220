import React, { useState } from "react";
import "./Reviews.css";
import data from "../../Data/data.json";
import Modal from "react-modal";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import {Container, createTheme, ThemeProvider } from "@mui/material";

function Reviews(props) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [index, setIndex] = useState(0);
  const [countAtATime, setCountAtATime] = useState(3);

  const { t } = useTranslation();

  const totalReviews = data.length;

  const handleBackClick = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
  };

  const handleForwardClick = () => {
    if (index < totalReviews) {
      setIndex(index + 1);
    }
  };

  const handleVideoClick = (url) => {
    setVideoUrl(url);
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setVideoUrl("");
    setModalIsOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      const newCount = window.innerWidth < 1200 ? 1 : 3;
      setCountAtATime(newCount);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1200,
        lg: 1500,
        xl: 1836,
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
    <Container
      maxWidth={"lg"}
    
      sx={{ marginTop: { md: "120px", xs: "100px" } }}
    >
      <div className="review-parent">
        <div className="bonic-magic-heading-container-parent">
          <div className="bonic-magic">
            <img src="/Features/key.png" alt="software" />
            <h3>  {t('testimony-side-heading')}</h3>
          </div>
        </div>

        <div className="review-container">
          {index > 0 && (
            <div
              className="review-arrow-back-container"
              onClick={handleBackClick}
            >
              <div className="review-arrow-back" style={{ fontSize: "2.5em" }}>
                &lsaquo;
              </div>
            </div>
          )}
          {data.slice(index, index + countAtATime).map((item) => (
            <div className="review-card" key={item.id} onClick={() => handleVideoClick(item.videoUrl)}>
              <div className="review-content">
                <div className="review-user">
                  <img
                    className="user-dp"
                    src={item.thumbnail}
                    alt="reviewers profile picure"
                  />
                  <div className="review-divider"></div>
                </div>
                <div className="review-text">
                  <h3>{item.textReview}</h3>
                </div>
                <div className="reviewer-info">
                  <h4>{item.name}</h4>
                  <img src="/Testimony/play.png" alt="" />
                </div>
              </div>
            </div>
          ))}
          {index < totalReviews - 1 && (
            <div
              className="review-arrow-forward-container"
              onClick={handleForwardClick}
            >
              <div
                className="review-arrow-forward"
                style={{ fontSize: "2.8em" }}
              >
                &rsaquo;
              </div>
            </div>
          )}
        </div>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={handleCloseModal}
          className="modal-parent"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              zIndex: 1000
            },
            content: {
              width: "60%",
              margin: "auto",
              background: "none",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 0,
              top: "50%",
              transform: "translateY(15%)"
            }
          }}
          onAfterOpen={() => {
            document.body.style.overflow = "hidden";
          }}
          onAfterClose={() => {
            document.body.style.overflow = "auto";
          }}
        >
          {window.innerWidth < 767 ? (
            <iframe
              src={videoUrl.replace("watch?v=", "embed/")}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              style={{ aspectRatio: "4/3", border: "none", marginTop: "100px" }}
            />
          ) : (
            <iframe
              width="900px"
              height="580px"
              src={videoUrl.replace("watch?v=", "embed/")}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              style={{ aspectRatio: "3/4", border: "none" }}
            />
          )}
        </Modal>
      </div>
    </Container>
    </ThemeProvider>
  );
}

export default Reviews;
