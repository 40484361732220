import React, { useEffect, useState } from "react";
import "./ProjectExpanded.css";

import { useParams } from "react-router-dom";
import { useDocument } from "../../../hooks/useDocument";

const ProjectExpanded = () => {
  const { projectTitle: r_title } = useParams();
  const titleArr = r_title.split(':');
  const { document: project, error } = useDocument(
    `project_categories/${titleArr[0]}/projects`,
    titleArr[1]
  );
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [thumbnailLoaded, setThumbnailLoaded] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleVideoClick = () => {
    setIsVideoPlaying(true);
  };

  const handleThumbnailLoad = () => {
    setThumbnailLoaded(true);
  };

  return (
    project !== null && (
      <div className="expanded-large-parent">
        <div className="expanded-parent">
          <div className="expanded-tittle-container">
            <h1>{project.title}</h1>
          </div>
          <div className="thumbnail-wrapper-parent" style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
  {!thumbnailLoaded && (
    <div className="loading-animation">
      <div className="radical-animation" />
    </div>
  )}
  <div className="iframe-container" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
    <iframe
      className={`video-thumbnail ${thumbnailLoaded ? "visible" : "hidden"}`}
      src={project.vidUrl}
      title="Video Player"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      onLoad={handleThumbnailLoad}
      style={{ width: '100%', height: '100%' }}
    />
  </div>
</div>


          <div className="expanded-intro-container">
            <p>{project.description}</p>
          </div>

          <div className="steps-intro-container">
            <div>
              <h3>Let's begin our exciting project journey</h3>
            </div>
            <div>
              <p>
                Here are some simple steps to get started. Let’s dive right in!
              </p>
            </div>
          </div>
          <div className="expanded-steps-container-parent">
            {project.steps.map((step, index) => (
              <div className="expanded-steps" key={index}>
                <div className="expanded-steps-container">
                  <div className="expanded-steps-container-h3">
                    <h3>Step {index + 1}:</h3>
                  </div>
                  <div className="expanded-steps-container-p">
                    <p>{step}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="masterpiece-container">
            <h3>Congratulations, maestro! </h3>
            <p>
              You've successfully crafted a masterpiece of innovation. Now, it's
              time to share your journey with the world! Upload your experience
              using the hashtag #bonicai and inspire others to embark on their
              own tinkering adventures. Remember, the sky's the limit when it
              comes to innovation and creativity!
            </p>
          </div>
        </div>
      </div>
    )
  );
};

export default ProjectExpanded;