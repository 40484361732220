import React from "react";
import { useState } from "react";
import { useLogin } from "../../hooks/useLogin";
import "./Login.css";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Button } from "@mui/material";


function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [email] = useState("");
  const [password] = useState("");
  const { login } = useLogin();
  const history = useHistory();

  const { t } = useTranslation();


  function loginWithEmailAndPassword(props) {
    // e.preventDefault();
    console.log("Loging in");
    login(props.email, props.password).then(() => {
      history.push("/preorder");
    });
  }

  const handleLoginSubmit = event => {
    event.preventDefault(); // prevent form from refreshing the page
    const emailInput = document.getElementById("email");
    const passwordInput = document.getElementById("password");
    validateEmail(emailInput);
    validatePassword(passwordInput);
    if (emailInput.checkValidity() && passwordInput.checkValidity()) {
      console.log("logging in");
      loginWithEmailAndPassword({email: emailInput.value, password: passwordInput.value});
    }
  };

  function validateEmail(emailInput) {
    // console.log("emailInput.value", emailInput.value);
    const email = emailInput.value.trim();
    if (email === "") {
      emailInput.setCustomValidity("Email is required");
    } else if (!/^\S+@\S+\.\S+$/.test(email)) {
      emailInput.setCustomValidity("Email is invalid");
    } else {
      emailInput.setCustomValidity("");
    }
  }

  function validatePassword(passwordInput) {
    const password = passwordInput.value;
    if (password === "") {
      passwordInput.setCustomValidity("Password is required");
    } else if (password.length < 8) {
      passwordInput.setCustomValidity(
        "Password must be at least 8 characters long"
      );
    } 
    // else if (
    //   !/[A-Z]/.test(password) ||
    //   !/[a-z]/.test(password) ||
    //   !/\d/.test(password) ||
    //   !/\W/.test(password)
    // ) {
    //   passwordInput.setCustomValidity(
    //     "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
    //   );
    // }
     else {
      passwordInput.setCustomValidity("");
    }
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="login-large-screem-parent">
      <div className="popup-container-login">
      
        <div className="popup-login">
          {/* {error && <h1>{error}</h1>} */}
          <div className="popup-login-inputs-container">
            <div className="popup-header-login">
              <h2>{t('login-main-heading')}</h2>
            </div>
            <div className="popup-body-login">
              <div className="signup-options">
                <div className="signup-option-google">
                  <button
                    className="login-btn-google"
                    onClick={() => {
                      login().then(() => {
                        history.push("/preorder");
                      });
                    }}
                  >
                    <img src="/Login/google.png" alt="" />
                    <p className="loginwithgoogle-p" style={{color:'#5C5C5C'}}>{t('login-google')}</p>
                  </button>
                </div>
                <div className="login-option-or">
                  <span>{t('login-or')}</span>
                </div>
              </div>
              <form className="login-form" onSubmit={handleLoginSubmit}>
                <label
                  htmlFor="email"
                  style={{
                    fontSize: "17px",
                    fontWeight: "700px",
                    color: " #222"
                  }}
                >
                  {t('login-name-heading')}
                </label>
                <input type="text" id="email" name="email" required placeholder="Enter your name"/>

                <label
                  htmlFor="password"
                  style={{
                    fontSize: "17px",
                    fontWeight: "700px",
                    color: " #222"
                  }}
                >
                  {t('login-password-heading')}
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  required
                  placeholder="Enter your password"
                />
                <span
                  className="password-toggle-icon"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword
                    ? <i className="fa fa-eye-slash" aria-hidden="true" />
                    : <i className="fa fa-eye" aria-hidden="true" />}
                </span>

                {/* <button className="login-btn-popup" type="submit">
                {t('log')}
                </button> */}
                <Button variant="contained" type="submit" sx={{width:"200px",marginTop:"50px",color:"white"}}>{t('login-main-heading')}</Button>
              </form>
              <div className="signup-link">
              {t('login-account-desc')} <a href="/signup">{t('signup-main-heading')}</a>
              </div>
            </div>
          </div>
          <div className="popup-login-side-image-container">
            <img src="Login/loginside.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
