import React, { useState, useEffect } from "react";
import "./GetaRoboPopup.css";

import { setDoc, doc } from "firebase/firestore";
import { db } from "../../Firebase/config.js";
import { useTranslation } from "react-i18next";

function GetaRoboPopup({ onClose }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const { t } = useTranslation();

  const handleClosePopup = () => {
    onClose();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const nameInput = event.target.name;
    const emailInput = event.target.email;

    if (!nameInput || !emailInput) {
      console.error("Name or email input is missing");
      return;
    }

    const name = nameInput.value;
    const email = emailInput.value;

    // Validate name input
    if (!name.trim()) {
      nameInput.style.border = "2px solid red";
      return;
    } else {
      nameInput.style.border = "2px solid green";
    }

    // Validate email input
    if (!email.trim() || !/\S+@\S+\.\S+/.test(email)) {
      emailInput.style.border = "2px solid red";
      return;
    } else {
      emailInput.style.border = "2px solid green";
    }

    try {
      const ref = doc(db, "leads", email);
      await setDoc(ref, {
        name: name,
        email: email,
        timestamp: new Date(),
      });
      console.log("Data saved successfully");
    } catch (error) {
      console.error("Error saving data: ", error);
    }

    setName("");
    setEmail("");
    onClose();
  };

  useEffect(() => {
    const body = document.querySelector("body");
    body.style.overflow = "hidden"; // Disable scrolling
    return () => {
      body.style.overflow = "visible"; // Enable scrolling when the popup is closed
    };
  }, []);

  return (
    <>
      <div className="popup-overlay" onClick={handleClosePopup} />
      <div className="popup-background">
        <div className="popup-container">
          <div className="popup-container-top">
            <div className="popup-container-top-heading">
              <h2>{t("getrobo-main-heading")}</h2>
              <p>{t("getrobo-sub-heading")}</p>
            </div>
          </div>
          <div className="popup-container-bottom">
            <div className="popup-input-image-container">
              <img
                className="popup-input-image"
                src="/Home/smallkidrobo.png"
                alt=""
              />
            </div>
            <div className="popoup-input-container">
              <form className="popup-form" onSubmit={handleSubmit}>
                <label
                  htmlFor=""
                  style={{ paddingLeft: "50px", marginBottom: "5px" }}
                >
                   {t('getrobo-name')}
                </label>
                <input
                  type="text"
                  name="name"
                  placeholder= {t('getrobo-name')}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  style={{ paddingLeft: "30px" }}
                />
                <label
                  htmlFor=""
                  style={{ paddingLeft: "50px", marginBottom: "5px" }}
                >
                 {t('getrobo-email')}
                </label>
                <input
                  type="email"
                  name="email"
                  placeholder={t('getrobo-email')}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ paddingLeft: "30px" }}
                />

                <button type="submit">{t('getrobo-getfies-desc')}</button>
              </form>

              <button className="close-popup-btn" onClick={handleClosePopup}>
                X
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GetaRoboPopup;
