const orderData = {
    name: 'John Doe',
    email: 'johndoe@example.com',
    phoneNumber: '555-555-5555',
    orders: [
      {
        id: '3354654654426',
        date: new Date('2023-04-15T14:48:00'),
        address: '123 Main St, Anytown USA',
        productName: 'BBRAIN',
        orderStatus: 'Confirmed',
        shippingMessage: 'We will notify you once the order is ready to be shipped!',
        quantity: 1,
        price: 399,
        discount: 100,
        discountPercentage : Math.floor((100 / 399) * 100),
        deliveryCharge: 0,
        total: 299,
        itemImage: '/User/productimg.png',
      },
      {
        id: 'ORD-2345-6789',
        date: new Date('2023-04-16T09:12:00'),
        address: '456 Elm St, Anytown USA',
        productName: 'Robotics Starter Kit',
        orderStatus: 'Pending',
        shippingMessage: 'Not shipped yet',
        quantity: 1,
        price: 100.00,
        discount: 20,
        discountPercentage: (20 / 100) * 100,
        deliveryCharge: 7.50,
        total: 100.00 - (100.00 * (20/100)) + 7.50,
        itemImage: '/User/productimg.png',
      },
    ],
  };
  
  export default orderData;
  