import React, { useState, useEffect, useRef } from "react";

import "./PreOrder.css";
import { useAuthContext } from "../../hooks/useAuthContext";
import { loadCheckout } from "../../Firebase/payment";
import { useHistory } from "react-router-dom";
import LoadingAnimation from "./LoadingAnimation";
import Hammer from "hammerjs";

import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";

const images = [
  { id: 1, url: "/PreOrder/preorderimg1.png" },
  { id: 2, url: "/PreOrder/preorderimg5.png" },
  { id: 3, url: "/PreOrder/preorderimg2.png" },
  { id: 4, url: "/PreOrder/preorderimg3.png" },
  { id: 5, url: "/PreOrder/preorderimg4.png" }
];

const imagesbrain = [
  { id: 1, url: "/PreOrder/bbrainchippreorder.png" },
  { id: 2, url: "/PreOrder/bbrainchippreorder2.png" },
  { id: 3, url: "/PreOrder/batterybrain.png" }
];

export default function PreOrder() {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(images[0]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const [selectedImageBrain, setSelectedImageBrain] = useState(imagesbrain[0]);
  const { user } = useAuthContext();
  const history = useHistory();
  const containerRef = useRef(null);
  const containerBrainRef = useRef(null);

  const { t } = useTranslation();

  function handleClick(image, index) {
    setSelectedImage(image);
    setSelectedImageIndex(index);
  }

  function handleClickBrain(image) {
    setSelectedImageBrain(image);
  }

  useEffect(() => {
    if (isLoading) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [isLoading]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const containerPreorder = containerRef.current;
    const containerBrain = document.querySelector(".brain-images-container");
    const hammerPreorder = new Hammer(containerPreorder);
    const hammerBrain = new Hammer(containerBrain);

    function handleSwipeLeftPreorder() {
      const currentIndex = images.findIndex(
        (image) => image.id === selectedImage.id
      );
      const nextIndex = (currentIndex + 1) % images.length;
      const nextImage = images[nextIndex];
      setSelectedImage(nextImage);
    }

    function handleSwipeRightPreorder() {
      const currentIndex = images.findIndex(
        (image) => image.id === selectedImage.id
      );
      const previousIndex = (currentIndex - 1 + images.length) % images.length;
      const previousImage = images[previousIndex];
      setSelectedImage(previousImage);
    }

    function handleSwipeLeftBrain() {
      const currentIndex = imagesbrain.findIndex(
        (image) => image.id === selectedImageBrain.id
      );
      const nextIndex = (currentIndex + 1) % imagesbrain.length;
      const nextImage = imagesbrain[nextIndex];
      setSelectedImageBrain(nextImage);
    }

    function handleSwipeRightBrain() {
      const currentIndex = imagesbrain.findIndex(
        (image) => image.id === selectedImageBrain.id
      );
      const previousIndex =
        (currentIndex - 1 + imagesbrain.length) % imagesbrain.length;
      const previousImage = imagesbrain[previousIndex];
      setSelectedImageBrain(previousImage);
    }

    hammerPreorder.on("swipeleft", handleSwipeLeftPreorder);
    hammerPreorder.on("swiperight", handleSwipeRightPreorder);

    hammerBrain.on("swipeleft", handleSwipeLeftBrain);
    hammerBrain.on("swiperight", handleSwipeRightBrain);

    return () => {
      hammerPreorder.off("swipeleft", handleSwipeLeftPreorder);
      hammerPreorder.off("swiperight", handleSwipeRightPreorder);

      hammerBrain.off("swipeleft", handleSwipeLeftBrain);
      hammerBrain.off("swiperight", handleSwipeRightBrain);
    };
  }, [selectedImage, selectedImageBrain]);

  async function handleCheckoutBrain() {
    if (user) {
      setIsLoading(true);
      document.body.classList.add("no-scroll");
      await loadCheckout("price_1MpbPNCiqRMSzN2K1OJomzpj", "BBRAIN", user);
      // setIsLoading(false);
    } else history.push("/login");
  }

  async function handleCheckoutKit() {
    if (user) {
      setIsLoading(true);
      document.body.classList.add("no-scroll");
      await loadCheckout("price_1N1E2YCiqRMSzN2KXjRH4ysH", "ROBOTICSKIT", user);
      // setTimeout(() => {
      //   setIsLoading(false);
      // }, 1000);
    } else {
      history.push("/login");
    }
  }

  return (
    <div className="preorder-large-container">
      {isLoading && <div className="loading">Loading...</div>}
      <div className="preorder-parent">
        <div className="brain-preorder-parent">
          <div className="preorder-starter-kit-heading-container" />
          {/* <div className="sidebar-bg-container" /> */}
          <div className="preorder-starter-kit-heading-container">
            <div className="preorder-heading-line" />
            <h2
              style={{ color: "#222", fontSize: "5.66vw", marginTop: "5px", fontWeight: "700" }}
            >
              {t("preorder-roboticskit-heading")}
            </h2>
          </div>
          <div className="brain-preorder-parent-container">
            <div className="b-brain-preorder-content-parent">
              <div
                className="brain-images-container-preorder"
                ref={containerRef}
              >
                <div className="selected-image-container-preorder-starter">
                  <img
                    src={process.env.PUBLIC_URL + selectedImage.url}
                    alt={`Selected pic with ID ${selectedImage.id}`}
                    className="selected-image-preorder"
                  />
                  <div className="image-indicator-starterpack">
                    {images.map((image) => (
                      <div
                        key={image.id}
                        className={`image-dot ${selectedImage.id === image.id ? "active-dot" : ""
                          }`}
                      ></div>
                    ))}
                  </div>
                </div>
                <div className="thumbnails-container-starterpack">
                  {images.map((image) => (
                    <img
                      key={image.id}
                      src={image.url}
                      alt={`pic with ID ${image.id}`}
                      className={`thumbnail-image ${selectedImage.id === image.id ? "active-thumbnail" : ""
                        }`}
                      onClick={() => handleClick(image)}
                    />
                  ))}
                </div>
              </div>

              <div className="brain-desc-preorder-starterpack">
                <div className="preprder-brain-heading-container">
                  <div className="preorder-heading-line" />
                  <h2>  {t("preorder-roboticskit-heading")}</h2>
                </div>
                <div className="brain-preorder-text">
                  <p style={{ marginBottom: "0" }}> {t("preorder-roboticskit-desc")}</p>
                </div>

                <div className="prorder-package-contents">
                  <h3> {t("product-package-includes")}</h3>
                  <p>. {t("preorder-roboticskit-package1")}</p>
                  <p>. {t("preorder-roboticskit-package2")}</p>
                  <p>. {t("preorder-roboticskit-package3")}</p>
                  <p>. {t("preorder-roboticskit-package4")}</p>
                  <p>. {t("preorder-roboticskit-package5")}</p>
                  <p>. {t("preorder-roboticskit-package6")}</p>
                </div>
                <div className="preorder-offer-preorderbtn-container">
                  <div className="preorder-offer-container">
                    <div className="preorder-offer-container-real">
                      <h3> {t("preorder-roboticskit-starterprice")}</h3>
                      <p>{t("preorder-product-left")} </p>
                    </div>
                    <h3>{t("preorder-roboticskit-starterprice-regular")}</h3>
                  </div>
                  <div className="preorder-btn-container">
                    {/* <button
                      disabled={isLoading}
                      className="preorder-preorder-btn"
                      onClick={handleCheckoutKit}
                    >
                      {isLoading ? "Loading..." : "CHECKOUT"}
                    </button> */}
                    <Button variant="contained" sx={{ borderRadius: "10px", color: "white", width: { xs: "20vw" }, maxWidth: "120px", fontSize: { xs: "2vw", sm: "12px" } }} disabled={isLoading} onClick={handleCheckoutKit}>
                      {isLoading ? "Loading..." : "CHECKOUT"}
                    </Button>
                    {isLoading && (
                      <div className="overlay-loading">
                        <LoadingAnimation />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-features-preorder-container-parent">
          <div className="main-features-preorder-container">
            <div className="main-features-preorder-container-contents">
              <div className="features-list-container">
                <div className="features-list-container-desc">
                  <img src="/PreOrder/portpreorder.png" alt="" />
                  <p> {t("preorder-ports-desc")}</p>
                </div>
                <div className="features-list-container-desc">
                  <img src="/PreOrder/wirelesspreorder.png" alt="" />
                  <p> {t("preorder-wireless-desc")}</p>
                </div>
                <div className="features-list-container-desc">
                  <img src="/PreOrder/rechargepreorder.png" alt="" />
                  <p> {t("preorder-battery-desc")}</p>
                </div>
                <div className="features-list-container-desc">
                  <img src="/PreOrder/rgbpreorder.png" alt="" />
                  <p> {t("preorder-rgb-desc")}</p>
                </div>
                <div className="features-list-container-desc">
                  <img src="/PreOrder/realaipreorder.png" alt="" />
                  <p> {t("preorder-ai-desc")}</p>
                </div>
              </div>

              <div className="features-list-container">
                <h3> {t("preorder-main-features-heading")}</h3>
                <div className="features-list-container-desc">
                  <img src="/PreOrder/mobwebpreorder.png" alt="" />
                  <p> {t("preorder-web-desc")}</p>
                </div>
                <div className="features-list-container-desc">
                  <img src="/PreOrder/multipreorder.png" alt="" />
                  <p> {t("preorder-language-desc")}</p>
                </div>
                <div className="features-list-container-desc">
                  <img src="/PreOrder/codingpreorder.png" alt="" />
                  <p> {t("preorder-block-desc")}</p>
                </div>
                <div className="features-list-container-desc">
                  <img src="/PreOrder/motionpreorder.png" alt="" />
                  <p> {t("preorder-motion-desc")}</p>
                </div>
                <div className="features-list-container-desc">
                  <img src="/PreOrder/pythonpreorder.png" alt="" />
                  <p> {t("preorder-python-desc")}</p>
                </div>
              </div>

              <div className="features-list-container">
                <div className="features-list-container-desc">
                  <img src="/PreOrder/screwpreorder.png" alt="" />
                  <p> {t("preorder-screw-desc")}</p>
                </div>
                <div className="features-list-container-desc">
                  <img src="/PreOrder/micpreorder.png" alt="" />
                  <p> {t("preorder-mic-desc")}</p>
                </div>
                <div className="features-list-container-desc">
                  <img src="/PreOrder/piezopreorder.png" alt="" />
                  <p> {t("preorder-piezo-desc")}</p>
                </div>
                <div className="features-list-container-desc">
                  <img src="/PreOrder/corepreorder.png" alt="" />
                  <p> {t("preorder-xtensa-desc")}</p>
                </div>
                <div className="features-list-container-desc">
                  <img src="/PreOrder/pinspreorder.png" alt="" />
                  <p> {t("preorder-pins-desc")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="features-list-container-mobile-parent">
          <h3

          >
            {t("preorder-main-features-heading")}
          </h3>
          <div className="features-list-container-mobile">
            <div className="features-list-container-desc">
              <img src="/PreOrder/portpreorder.png" alt="" />
              <p> {t("preorder-ports-desc")}</p>
            </div>
            <div className="features-list-container-desc">
              <img src="/PreOrder/wirelesspreorder.png" alt="" />
              <p> {t("preorder-wireless-desc")}</p>
            </div>
            <div className="features-list-container-desc">
              <img src="/PreOrder/rechargepreorder.png" alt="" />
              <p> {t("preorder-battery-desc")}</p>
            </div>
            <div className="features-list-container-desc">
              <img src="/PreOrder/rgbpreorder.png" alt="" />
              <p> {t("preorder-rgb-desc")}</p>
            </div>
            <div className="features-list-container-desc">
              <img src="/PreOrder/realaipreorder.png" alt="" />
              <p> {t("preorder-ai-desc")}</p>
            </div>
            <div className="features-list-container-desc">
              <img src="/PreOrder/piezopreorder.png" alt="" />
              <p> {t("preorder-piezo-desc")}</p>
            </div>
            <div className="features-list-container-desc">
              <img src="/PreOrder/corepreorder.png" alt="" />
              <p> {t("preorder-xtensa-desc")}</p>
            </div>
            <div className="features-list-container-desc">
              <img src="/PreOrder/pinspreorder.png" alt="" />
              <p> {t("preorder-pins-desc")}</p>
            </div>
          </div>

          <div
            className="features-list-container-mobile"
            style={{ marginLeft: "11.84vw" }}
          >
            <div className="features-list-container-desc">
              <img src="/PreOrder/mobwebpreorder.png" alt="" />
              <p> {t("preorder-web-desc")}</p>
            </div>
            <div className="features-list-container-desc">
              <img src="/PreOrder/multipreorder.png" alt="" />
              <p> {t("preorder-language-desc")}</p>
            </div>
            <div className="features-list-container-desc">
              <img src="/PreOrder/codingpreorder.png" alt="" />
              <p> {t("preorder-block-desc")}</p>
            </div>
            <div className="features-list-container-desc">
              <img src="/PreOrder/motionpreorder.png" alt="" />
              <p> {t("preorder-motion-desc")}</p>
            </div>
            <div className="features-list-container-desc">
              <img src="/PreOrder/pythonpreorder.png" alt="" />
              <p> {t("preorder-python-desc")}</p>
            </div>
            <div className="features-list-container-desc">
              <img src="/PreOrder/screwpreorder.png" alt="" />
              <p> {t("preorder-screw-desc")}</p>
            </div>
            <div className="features-list-container-desc">
              <img src="/PreOrder/micpreorder.png" alt="" />
              <p> {t("preorder-mic-desc")}</p>
            </div>
          </div>
        </div>



        {/* Mobile design */}

        <div className="b-brain-preorder-content-parent-bottom">
          <div className="preprder-brain-heading-container" />
          <div className="preorder-mobile-brain-parent-cont">
            <div className="preorder-heading-line" />
            <h2
              style={{ color: "#222", fontSize: "5.66vw", marginTop: "5px", fontWeight: "700" }}
            >
              {t("preorder-brain-heading")}
            </h2>
          </div>
          <div className="brain-images-container" ref={containerBrainRef}>
            <div className="selected-image-container-preorder">
              <img
                src={process.env.PUBLIC_URL + selectedImageBrain.url}
                alt={`Selected pic with ID ${selectedImageBrain.id}`}
                className="selected-image-preorder"
              />
              <div className="image-indicator">
                {imagesbrain.map((image) => (
                  <div
                    key={image.id}
                    className={`image-dot ${selectedImageBrain.id === image.id ? "active-dot" : ""
                      }`}
                  ></div>
                ))}
              </div>
            </div>
            <div className="thumbnails-container-brain">
              {imagesbrain.map((image) => (
                <img
                  key={image.id}
                  src={image.url}
                  alt={`pic with ID ${image.id}`}
                  className={`thumbnail-image ${selectedImageBrain.id === image.id ? "active-thumbnail" : ""
                    }`}
                  onClick={() => handleClickBrain(image)}
                />
              ))}
            </div>
          </div>
          <div className="brain-desc-preorder">
            <div className="preprder-brain-heading-container">
              <div className="preorder-heading-line" />
              <h2>   {t("preorder-brain-heading")}</h2>
            </div>
            <div className="brain-preorder-texts">
              <p>{t("preorder-brain-desc")}</p>
            </div>

            <div className="prorder-package-contents">
              <h3>{t("product-package-includes")}</h3>
              <p>. {t("preorder-roboticskit-package1")}</p>
              <p>. {t("preorder-roboticskit-package2")}</p>
            </div>
            <div className="preorder-offer-preorderbtn-container">
              <div className="preorder-offer-container">
                <h3>{t("preorder-brainkit-starterprice-regular")}</h3>
                <div className="preorder-offer-container-real">
                  <h3> {t("preorder-brainkit-starterprice")} </h3>
                  <p> {t("preorder-product-left")}</p>
                </div>
              </div>
              <div className="preorder-btn-container">
                {/* <button
                  disabled={isLoading}
                  className="preorder-preorder-btn"
                  onClick={handleCheckoutBrain}
                >
                  {isLoading ? "Loading..." : "CHECKOUT"}
                </button> */}
                <Button
                  variant="contained"
                  onClick={handleCheckoutBrain}
                  disabled={isLoading}
                  sx={{ borderRadius: "10px", color: "white", width: { xs: "20vw" }, maxWidth: "120px", fontSize: { xs: "2vw", sm: "12px" } }}
                >

                  {isLoading ? "Loading..." : "CHECKOUT"}
                </Button>
                {isLoading && (
                  <div className="overlay-loading">
                    <LoadingAnimation />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="shipment-container-main-parent">
          <div className="shipment-container-parent">
            <div className="shippayment-conatiner">
              <div className="freeshipping-container">
                <img src="/PreOrder/shiptruck.png" alt="" />
                <div className="freeshipping-container-desc">
                  <h6> {t("preorder-shipping-heading")}</h6>
                  <p> {t("preorder-shipping-desc")}</p>
                </div>
              </div>
            </div>
            <div className="shippayment-conatiner">
              <div className="freeshipping-container">
                <img src="/PreOrder/payment.png" alt="" />
                <div className="freeshipping-container-desc">
                  <h6> {t("preorder-payment-heading")}</h6>
                  <p> {t("preorder-payment-desc")}</p>
                </div>
              </div>
            </div>
            <div className="shippayment-conatiner">
              <div className="freeshipping-container">
                <img src="/PreOrder/qualityassured.png" alt="" />
                <div className="freeshipping-container-desc">
                  <h6> {t("preorder-quality-heading")}</h6>
                  <p> {t("preorder-qualityt-desc")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="aboutus-footer-parent-container">
          <div className="aboutus-footer-parent" />
        </div> */}
      </div>
    </div>
  );
}
