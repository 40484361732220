import React from "react";
import { useTranslation } from "react-i18next";

import "./AboutUs.css";

function About() {
  const { t } = useTranslation();

  return (
    <div className="aboutus-large-parent">
      <div className="aboutus-parent">
        <div className="lightbulb-container">
          <div className="lightbulb-text-container">
            <p className="lightbulb-heading-main">
              {t("aboutus-hero-section")}
            </p>
            <p
              className="lightbulb-heading-main-mobile"
              style={{ fontWeight: "600" }}
            >
              {/* {t("aboutus-mobile-head-1")} <br />
              {t("aboutus-mobile-head-2")}
              <br />
              {t("aboutus-head-3")} */}
              {t("aboutus-hero-section")}
            </p>
            <div className="lightbulb-image-container">
              <img src="AboutUs/boniclogo.png" alt="" />
            </div>
          </div>
        </div>

        <div className="who-are-we-container">
          <h3>{t("aboutus-we-are")}</h3>
          <p className="lightbulb-heading-main-span">
            {t("aboutus-we-are-desc")}
          </p>
        </div>

        <div className="meet-container-sahir" style={{ marginTop: "100px" }}>
          <div className="meet-container-sahir-heading">
            <p className="meet-container-meet">{t("aboutus-meet-text")}</p>
            <p className="meet-container-name">{t("aboutus-shahirsir-name")}</p>
          </div>
          <div className="meet-container-sahir-desc">
            <div className="meet-container-sahir-img">
              <img src="/AboutUs/sahirsir.png" alt="" />
            </div>
            <div className="meet-container-sahir-desc-p">
              <p>{t("aboutus-shahirsir-p1")}</p>
              <p>{t("aboutus-shahirsir-p2")}</p>
              <p>{t("aboutus-shahirsir-p3")}</p>
            </div>
          </div>
        </div>
        <div className="meet-container-azhar">
          <div className="meet-container-azhar-heading">
            <p className="meet-container-meet-azhar">{t("aboutus-meet-text")}</p>
            <p className="meet-container-name">{t("aboutus-azharrsir-name")}</p>
          </div>
          <div className="meet-container-sahir-desc">
            <div className="meet-container-azhar-img">
              <img src="/AboutUs/azharsir.png" alt="" />
            </div>
            <div className="meet-container-sahir-desc-p">
            <p>{t("aboutus-azharsir-p1")}</p>
              <p>{t("aboutus-azharsir-p2")}</p>
              <p>{t("aboutus-azharsir-p3")}</p>
            </div>
          </div>
        </div>

        <div className="ourstory-container">
          <div className="ourstory-conainer-heading">
            <h3>{t("aboutus-story-heading")}</h3>
            <p>
            {t("aboutus-story-desc")}
            </p>
          </div>
        </div>

        <div className="aboutus-prototype-parent">
          <div className="aboutus-ptototype-main-container">
            <div className="aboutus-prototype-numbers-container">
              <div className="protype-numbers">
                <h1 style={{ color: " #1C94CF", marginBottom: "-18px" }}>
                  06{" "}
                </h1>
                <h2>05 </h2>
                <h3>04 </h3>
                <h4>03 </h4>
                <h5>02 </h5>
                <h6>01 </h6>
              </div>
              <div className="prototype-dots">
                <h1 style={{ color: " #174A62" }}>.</h1>
                <h2>.</h2>
                <h3>.</h3>
                <h4>.</h4>
                <h5>.</h5>
                <h6>.</h6>
              </div>
            </div>

            <div className="prototype-kit-container">
              <h3>Prototype</h3>

              <div className="prototype-kit-main-wrappper">
                <div className="kit-components-container">
                  <div className="prototypecontents-mobile">
                    <img src="/AboutUs/proto.png" alt="" />
                  </div>

                  <div className="kit-contents1">
                    <div className="kit-contents-desc">
                      <img src="/AboutUs/1st.png" alt="" />
                      <p>To validate the idea</p>
                    </div>
                  </div>

                  <div className="kit-contents2">
                    <div className="kit-contents-desc">
                      <img src="/AboutUs/2nd.png" alt="" />
                      <p>To test the idea</p>
                    </div>
                  </div>

                  <div className="kit-contents3">
                    <div className="kit-contents-desc">
                      <img src="/AboutUs/3rd.png" alt="" />
                      <p>Scale the idea</p>
                    </div>
                  </div>

                  <div className="kit-contents4">
                    <div className="kit-contents-desc">
                      <img src="/AboutUs/4th.png" alt="" />
                      <p>Make it better</p>
                    </div>
                  </div>

                  <div className="kit-contents5">
                    <div className="kit-contents-desc">
                      <img src="/AboutUs/5th.png" alt="" />
                      <p>Add more Features</p>
                    </div>
                  </div>

                  <div className="kit-contents6">
                    <div className="kit-contents-desc">
                      <img src="/AboutUs/6th.png" alt="" />
                      <p>Add AI Capabilities</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="proto-cartoon-container">
          <img src="/AboutUs/protocartoon.png" alt="" />
        </div>
        <div className="ourvalues-parent">
          <div className="ourvalues-content-container">
            <div className="ourvalues-contents-1">
              <h2>{t("aboutus-values-heading")}</h2>
              <div className="innovation-container-aboutus">
                <h3>{t("aboutus-values-innovation-heading")}</h3>
                <p>{t("aboutus-values-innovation-desc")}</p>
              </div>
              <div className="fun-container-aboutus">
                <h3>{t("aboutus-values-fun-heading")}</h3>
                <p>{t("aboutus-values-fun-desc")}</p>
              </div>
            </div>
            {/* <div className="ourvalues-rocket-image-parent">
              <img src="/AboutUs/ourvaluesbgmobile.png" alt="" />
            </div> */}
            <div className="ourvalues-contents-2">
              <div className="passion-container-aboutus">
                <h3>{t("aboutus-values-passion-heading")}</h3>
                <p>{t("aboutus-values-passion-desc")}</p>
              </div>
              <div className="customer-container-aboutus">
                <h3>{t("aboutus-values-customer-heading")}</h3>
                <p>{t("aboutus-values-customer-desc")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
