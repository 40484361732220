import React, { useState } from "react";
import { useSignup } from "../../hooks/useSignup";
import { useTranslation } from "react-i18next";

import "./SignUp.css";
import { Button } from "@mui/material";

function SignUp() {
  const { t } = useTranslation();
  const { signup } = useSignup();

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSignupSubmit = (event) => {
    event.preventDefault(); // prevent form from refreshing the page

    validateFullName();
    validateEmail();
    validatePassword();

    if (fullName && email && password) {
      console.log("creating new user");
      signup(email, password);
    }
  };

  function validateFullName() {
    if (fullName.trim() === "") {
      // Handle validation error
      console.log("Full name is required");
    } else if (/\d|\W/.test(fullName)) {
      // Handle validation error
      console.log(
        "Full name cannot contain numbers or special characters"
      );
    }
  }

  function validateEmail() {
    if (email.trim() === "") {
      // Handle validation error
      console.log("Email is required");
    } else if (!/^\S+@\S+\.\S+$/.test(email)) {
      // Handle validation error
      console.log("Email is invalid");
    }
  }

  function validatePassword() {
    if (password === "") {
      // Handle validation error
      console.log("Password is required");
    } else if (password.length < 8) {
      // Handle validation error
      console.log("Password must be at least 8 characters long");
    } else if (
      !/[A-Z]/.test(password) ||
      !/[a-z]/.test(password) ||
      !/\d/.test(password) ||
      !/\W/.test(password)
    ) {
      // Handle validation error
      console.log(
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
      );
    }
  }

  return (
    <div className="signup-large-screen-parent">
      <div className="popup-container-signup">
        <div className="popup-signup">
          <div className="popup-signup-inputs-container">
            <div className="popup-header-signup">
              <h2>{t("signup-main-heading")}</h2>
            </div>
            <div className="popup-body-signup">
              <form className="signup-form" onSubmit={handleSignupSubmit}>
                <label
                  htmlFor="fullname"
                  style={{
                    fontSize: "17px",
                    fontWeight: "700px",
                    color: " #222"
                  }}
                >
                  {t("login-name-heading")}
                </label>
                <input
                  type="text"
                  id="fullname"
                  name="fullname"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  placeholder="Enter your name"
                  required
                />

                <label
                  htmlFor="email"
                  style={{
                    fontSize: "17px",
                    fontWeight: "700px",
                    color: " #222"
                  }}
                >
                  {t("signup-email-heading")}
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  required
                />

                <label
                  htmlFor="password"
                  style={{
                    fontSize: "17px",
                    fontWeight: "700px",
                    color: " #222"
                  }}
                >
                  {t("login-password-heading")}
                </label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your password"
                  required
                />

                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    color: "white",
                    width: { xs: "30vw", md: "200px" },
                    marginLeft: { xs: "20vw", md: "150px" },
                    marginTop: "50px"
                  }}
                >
                  {t("signup-main-heading")}
                </Button>
              </form>

              <div className="login-link">
                {t("signup-account-desc")}
                <a href="/login">{t("login-main-heading")}</a>
              </div>
            </div>
          </div>
          <div className="popup-signup-side-image-container">
            <img src="Images/signup-popup-side-image.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
