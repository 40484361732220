import { useEffect, useState } from "react";
import "./CodeIt.css";
import { BsAndroid2, BsApple, BsLaptop } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';


function CodeIt() {

  const { t } = useTranslation();

  const [showWebappButton, setShowWebappButton] = useState(false);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= 767) {
        setShowWebappButton(true);
      } else {
        setShowWebappButton(false);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function redirectToGooglePlay() {
    window.location.href = "https://play.google.com/store/apps/details?id=com.autobonics.codeit&pli=1";
  }
  function redirectToAppStore() {
    window.location.href = "https://apps.apple.com/in/app/codeit-program-your-bonic/id6448219080";
  }

  return (
    <div className="Codeit-page-big-parent">
      <div className="codeit-page-main-container">
          <div className="codeit-device-conatiner">
            <img src="/CodeIt/devicescodeit.png" alt="devices" />
          </div>
        <div className="codeit-page-dec-parent">
          <h3>
          {t('codeit-hero-section')}         
          </h3>
        </div>
        <div className="codeit-page-dec-parent-mobile">
        {t('codeit-hero-section')}    
        </div>
        <div className="codeit-page-buttons-container">
          <button onClick={redirectToGooglePlay}>
            <BsAndroid2 /> <p>{t('codeit-download-app')} </p>
          </button>
          <button onClick={redirectToAppStore}>
            <BsApple /> <p>{t('codeit-download-app')} </p>
          </button>
          {showWebappButton && (
            <Link
              style={{ textDecoration: "none" }}
              to={{ pathname: "https://code.bonic.ai" }}
              target="_blank"
            >
              <button className="webapp-button">
                <BsLaptop />
                <p>{t('codeit-web-app')} </p>
              </button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default CodeIt;
