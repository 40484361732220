import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, Timestamp } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from 'firebase/functions';
import { getAnalytics } from 'firebase/analytics'
// import { getDatabase } from "firebase/database";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCeVTXFQUP_VGOUn0M2m3v1lFf1vEiO3h0",
  authDomain: "bonic-1302b.firebaseapp.com",
  projectId: "bonic-1302b",
  storageBucket: "bonic-1302b.appspot.com",
  messagingSenderId: "680405675103",
  appId: "1:680405675103:web:15df87478a91a5655d7fea",
  measurementId: "G-Z4CZDX59N0"
};

// init firebase
const app = initializeApp(firebaseConfig);

// init services
// const rtdb = getDatabase();
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
const functions = getFunctions(app);
const analytics = getAnalytics(app);

// timestamp 
const timestamp = Timestamp;

export { db, auth, storage, timestamp, functions };
