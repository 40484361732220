import React from "react";

import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Card,
  Container,
  Typography,
  createTheme,
  ThemeProvider
} from "@mui/material";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1200,
      lg: 1500,
      xl: 1836
    }
  }
});

const cardData = [
  {
    imageSrc: "/Features/satellite.svg",
    heading: "features-wireless-heading",
    description: "features-wireless-description"
  },
  {
    imageSrc: "/Features/arabic.svg",
    heading: "features-arabic-heading",
    description: "features-arabic-description"
  },
  {
    imageSrc: "/Features/chip.svg",
    heading: "features-modular-heading",
    description: "features-modular-description"
  },
  {
    imageSrc: "/Features/automation.svg",
    heading: "features-ai-heading",
    description: "features-ai-description"
  }
];

function FeatureCard({ imageSrc, heading, description }) {
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        background: "rgba(0, 0, 0, 1)",
        display: "flex",
        flexDirection: "column",
        width: { md: "450px", xs: "90vw" },
        height: { md: "450px", xs: "370px" },
        borderRadius: "60px",
        alignItems: "center",
        justifyContent: "center",
        rowGap: "25px"
      }}
    >
      <img src={imageSrc} alt="" style={{ width: "85px" }} />
      <Typography variant="h5" sx={{ color: "#FFF", fontWeight: "700",maxWidth:{xs:"80vw",md:"300px"},textAlign:"center" }}>
        {t(heading)}
      </Typography>
      <Typography
        variant="p"
        sx={{
          color: "#FFF",
          fontWeight: "500",
          paddingLeft: { md: "55px", xs: "6vw" },
          paddingRight: { md: "55px", xs: "6vw" },
          textAlign: { xs: "center", md: "left" }
        }}
      >
        {t(description)}
      </Typography>
    </Card>
  );
}

function Features() {
  const history = useHistory();

  const { t } = useTranslation();



  
  const handlePreorderButtonClick = () => {
    history.push("/preorder");
  };


  return (
    <ThemeProvider theme={theme}>
     <Container maxWidth="lg" sx={{ marginTop: "50px", }}>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            paddingLeft: { xs: "20px" }
          }}
        >
          <Typography
            variant="h5"
            sx={{
              color: "rgba(28, 148, 207, 1)",
              fontWeight: "800",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              columnGap: "25px"
            }}
          >
            <img src="/Features/key.png" alt="key features" />
            {t("features-side-heading")}
          </Typography>
        </Box>

        <Box
          sx={{
            marginTop: "40px",
            display: "flex",
            alignItems: "center",
            marginLeft: { md: "200px", xs: "2vw" },
            justifyContent: "flex-start"
          }}
        >
          <Typography
            variant="h3"
            sx={{
              color: "#060000",
              fontWeight: "700",
              lineHeight: "normal",
              paddingRight: { xs: "1vw", md: "0" },
              maxWidth: { xs: "100vw", md: "1000px" }
            }}
          >
            {t("features-hero-section")}
          </Typography>
        </Box>

        <Container
          sx={{
            display: "flex",
            background: "url(/Features/bg.svg)",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: { xs: "center" },
            rowGap: { xs: "10vw", md: "46px" },
            marginTop: {md:"100px",xs:"10px"}
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { md: "row", xs: "column" },
              justifyContent: "center",
              color: "#FFF",
              columnGap: {md:"200px"},
              rowGap:{xs:"10vw"},
              marginTop:" 90px"
            
            }}
          >
            {cardData.slice(0, 2).map((card, index) => (
              <FeatureCard
                key={index}
                imageSrc={card.imageSrc}
                heading={card.heading}
                description={card.description}
              />
            ))}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: { md: "row", xs: "column" },
              justifyContent: "center",
              columnGap: "200px",
              rowGap:{xs:"10vw"},
             
            }}
          >
            {cardData.slice(2, 4).map((card, index) => (
              <FeatureCard
                key={index}
                imageSrc={card.imageSrc}
                heading={card.heading}
                description={card.description}
              />
            ))}
          </Box>
        </Container>

        <Typography
          variant="h2"
          sx={{
            color: "#060000",
            textAlign: "center",
            fontWeight: "700",
            marginTop: "150px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          {t("features-getstarted")}
        </Typography>

        <Typography
          variant="h5"
          sx={{
            color: "#060000",
            textAlign: "center",
            fontWeight: "600",
            marginTop: "55px",
            padding: { xs: "5vw", md: "0" }
            // maxWidth:{xs:"80vw",md:"700px"}
          }}
        >
          {t("features-getstarted-sub-desc")}
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: { md: "145px", xs: "50px" }
          }}
        >
          <Button
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "#1C94CF",
              fontWeight: "500",
              color: "white"
            }}
            variant="contained"
            onClick={handlePreorderButtonClick}
          >
            {t("featured-shop-btn")}
          </Button>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default Features;
