import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Card,
  Container,
  Typography,
  TextField,
  InputAdornment,
  createTheme, 
  ThemeProvider 
} from "@mui/material";

function Software() {

  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);

  const handleSubscribe = (e) => {
    e.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError(true);
      return;
    }

    console.log(email);

    // Clear the input field and error state
    setEmail("");
    setError(false);
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
    setError(false);
  };


  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1200,
        lg: 1500,
        xl: 1836,
      },
    },
  });
  return (
    <Container maxWidth={"lg"}  sx={{ marginTop: "295px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
          marginLeft: { md: "0px", xs: "20px" },
          justifyContent: { md: "space-between", xs: "left" },
          paddingRight: "0px"
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: { md: "center", xs: "baseline" } }}
        >
          <Typography
            variant="h4"
            sx={{
              color: "rgba(28, 148, 207, 1)",
              fontWeight: "800",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              columnGap: "25px"
            }}
          >
            <img src="/Features/key.png" alt="software" />
             {t('software-side-heading')}
          </Typography>
        </Box>
        <Typography
          variant="h1"
          component="h2"
          sx={{
            fontWeight: "700",
            position: { md: "inherit", xs: "absolute" },
            right: { md: "0", xs: "6vw" },
            marginTop: { md: "0", xs: "80px" }
          }}
        >
          {t('software-right-side-heading')}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", xs: "column", sm: "column" },
          alignItems: "center",
          justifyContent: "center",
          marginTop: "130px",
          columnGap: { md: "165px", xs: "20vw" }
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            rowGap: { md: "108px", xs: "45px" },
            alignItems: "center"
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: "700",
              width: { md: "418px", xs: "80vw" },
              textAlign: { md: "left", xs: "center" }
            }}
          >
              {t('software-download-description')}
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontWeight: "600",
              color: "#222 ",
              width: { md: "418px", xs: "80vw" },
              textAlign: { md: "left", xs: "center" }
            }}
          >
             {t('software-download-sub-description')}
          </Typography>
        </Box>
        <Box sx={{ marginTop: { md: "0", xs: "30px", sm: "35px" } }}>
          <img
            style={{
              width: "100%",
              "@media (max-width: 600px)": {
                width: "50vw"
              }
            }}
            src="/Software/codeit.png"
            alt="codeit"
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          marginTop: "120px",
          rowGap: { md: "47px", xs: "80px" }
        }}
      >
        <Button
          variant="contained"
          sx={{
            background: "white",
            fontWeight: "700",

            // width:{md: "360px",xs:"30vw"},
            // height:{md:"57px",sx:"38px"},

            color: "black",

            boxShadow:
              "0px 1.3966480493545532px 6.983240127563477px 0px rgba(101, 192, 237, 0.50)"
          }}
        >
           {t('software-download-btn')}
        </Button>
        <img
          style={{
            marginTop: "0",
            "@media (max-width: 600px)": {
              marginTop: "35px"
            }
          }}
          src="/Software/logo.svg"
          alt="Download"
        />
      </Box>
      <Box
        sx={{
          marginTop: { md: "300px", xs: "80px" },
          marginBottom: { md: "200px", xs: "50px" },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Typography
          variant="h3"
          sx={{
            color: "#060000",
            fontWeight: "700",
            textAlign: { md: "left", xs: "center" }
          }}
        >
          {t('software-updated-heading')}
        </Typography>
        <Typography
          sx={{
            marginTop: "60px",
            color: "#000",
            fontSize: "16px",
            fontWeight: "600",
            textAlign: "center",
            width: { md: "450px", xs: "80vw" }
          }}
        >
          {t('software-updated-desc')}
        </Typography>
        <form
          onSubmit={handleSubscribe}
          style={{
            marginTop: "60px",
            paddingLeft: { md: "0", xs: "20px" },
            paddingRight: { md: "0", xs: "20px" }
          }}
        >
          <TextField
            id="email"
            label= {t('software-email-placeholder')}
            variant="filled"
            value={email}
            onChange={handleChange}
            error={error}
            helperText={error ? "Please enter a valid email address" : ""}
            fullWidth
            required
            sx={{
              backgroundColor: "black",
              "& .MuiFilledInput-input": {
                color: "white"
              },
              "& .MuiFilledInput-underline:before": {
                borderBottomColor: "white"
              },
              "& .MuiFilledInput-underline:after": {
                borderBottomColor: "white"
              },
              "& .MuiFormLabel-root": {
                color: "white"
              }
              //   width:{xs:"173px",md:"352px"}
              //   ,
              //   borderRadius: "44px"
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ padding: "10px" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      // paddingLeft: "30px",
                      // paddingRight: "30px",
                      backgroundColor: "white",
                      color: "black"
                      //   borderRadius: {md :"31px",xs:"26px"},
                      //   width:{xs:"63px",sm:"70px",md:"128px"}
                    }}
                  >
                   {t('software-subscription-btn')}
                  </Button>
                </InputAdornment>
              )
            }}
          />
        </form>
      </Box>
    </Container>
   
  );
}

export default Software;
