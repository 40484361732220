import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import "typeface-dm-sans";
import { AuthContextProvider } from "./context/AuthContext";
import ReactGA from "react-ga4";
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

ReactGA.initialize("G-PT8YYXX7YV");
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthContextProvider>
    <I18nextProvider i18n={i18n}>
    <App />
    </I18nextProvider>
    </AuthContextProvider>
  </React.StrictMode>
);

// const SendAnalytics = ()=> {
//   ReactGA.send({
//     hitType: "pageview",
//     page: window.location.pathname,
//   });
// }
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(SendAnalytics);