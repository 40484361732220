import React, { useState } from "react";

import "./User.css";
//This is orderData taken from viewModel
import orderData from "./ViewModel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";

function User() {
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [showAddress, setShowAddress] = useState(
    new Array(orderData.orders.length).fill(false)
  );

  //This is where is authenticated use data is present. User data
  //must always be used like this.
  const { user } = useAuthContext();

  const [isRotated, setIsRotated] = useState(
    new Array(orderData.orders.length).fill(false)
  );

  const [showPersonalDashboard, setShowPersonalDashboard] = useState(true);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 767) {
        setShowPersonalDashboard(false);
      } else {
        setShowPersonalDashboard(true);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClickIcon = (index) => {
    setIsRotated((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };
  // const toggleOrderDetails = () => {
  //   setShowOrderDetails(!showOrderDetails);

  //   // Check screen size and update showPersonalDashboard state accordingly
  //   if (window.innerWidth < 767) {
  //     setShowPersonalDashboard(false);
  //   }
  // };

  const toggleAddress = (orderIndex) => {
    setShowAddress((prevState) => {
      const newState = [...prevState];
      newState[orderIndex] = !newState[orderIndex];
      return newState;
    });
  };

  return (
    <div className="user-dashboard-parent-large-container">
      <div className="user-dashboard-parent-container">
        <div className="user-dashboard-parent">
          {showPersonalDashboard && (
            <div className="user-personal-dashboard-parent">
              <div className="user-personal-data-avatar">
                <div className="avatar-initial">
                  {user.name?.charAt(0).toUpperCase()}
                </div>
                <div className="user-personal-data-avatar-details">
                  <h5>{user.name ?? "Hey !"}</h5>
                  <p style={{ marginTop: "5px" }}>{user.email}</p>
                </div>
              </div>
              <div className="orders-help-parent">
                <div
                  className="my-orders-container"
                  // onClick={toggleOrderDetails}
                >
                  <i class="fas fa-shopping-bag"></i>
                  <span>My Orders</span>
                </div>
                <div className="help-container">
                  <i class="fas fa-question-circle"></i>
                  <span>Help</span>
                </div>
              </div>
            </div>
          )}
          {
            <div className="no-order-class">
              <h3>
                You don't have any orders right now.
                <br />
                Order now! only few stock remaining.
              </h3>
            </div>
          }
          {showOrderDetails && (
            <div className="user-order-details-parent">
              {orderData.orders.map((order, index) => (
                <div key={order.id} className="order-details-container">
                  <div className="order-mobile-only-container">
                    <h3>My Orders</h3>
                  </div>
                  <div className="order-id-container">Order ID:{order.id}</div>
                  <div className="order-address-amount-container">
                    <div className="product-img-container">
                      <img src={order.itemImage} alt="Product" />
                    </div>
                    <div className="order-status-container">
                      <h4>{order.productName}</h4>
                      <h5>
                        Order {order.orderStatus}{" "}
                        <i class="fas fa-circle-check"></i>{" "}
                      </h5>
                      <p>{order.shippingMessage}</p>
                    </div>
                    <div className="order-billing-amount-container">
                      <h4>AED{order.price}</h4>
                      <p>Qty:{order.quantity}</p>
                    </div>
                    <div
                      className="dropdown-arrow-continer"
                      onClick={() => toggleAddress(index)}
                    >
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        className={isRotated[index] ? "rotate-icon" : ""}
                        onClick={() => handleClickIcon(index)}
                      />
                    </div>
                  </div>
                  <div className="order-address-under-line"></div>
                  <div className="order-date-container">
                    <p>Order date:</p>
                    <h5>
                      {order.date.toLocaleDateString("en-US", {
                        month: "short",
                        day: "numeric",
                        year: "numeric"
                      })}
                    </h5>
                  </div>
                  {showAddress[index] && (
                    <div className="order-address-container-dropdown">
                      <div className="order-dropdown-side-container">
                        <h3>Delivery</h3>
                        <h4>Address</h4>
                        <p>{order.address}</p>
                        <h4>{user.phoneNumber}</h4>
                      </div>
                      <div className="order-summary-container">
                        <h3>Order Summary</h3>
                        <div className="price-container-dropdown">
                          <p>price</p>
                          <span>{order.price}</span>
                        </div>
                        <div className="discount-container-dropdown">
                          <p>Discount</p>
                          <span>
                            ({order.discountPercentage}%)-{order.discount}
                          </span>
                        </div>
                        <div className="delivery-container-dropdown">
                          <p>Delivery</p>
                          <span>{order.deliveryCharge}</span>
                        </div>
                        <div className="delivery-under-dotted-line"></div>
                        <div className="total-container-dropdown">
                          <p>Total</p>
                          <span
                            style={{ fontWeight: "bolder", color: "black" }}
                          >
                            AED{order.total}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default User;
