import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, Card, Grid, Typography, createTheme, ThemeProvider } from "@mui/material";
import Container from "@mui/material/Container";
import { useTranslation } from 'react-i18next';

const cardData = [
  {
    number: "1",
    buttonText: "magicrobo-kit-heading",
    description: "magicrobo-kit-desc",
    redirectPath: "/preorder",
  },
  {
    number: "2",
    buttonText: "magicrobo-download-heading",
    description: "magicrobo-download-desc",
    redirectPath: "/codeit",
  },
  {
    number: "3",
    buttonText: "magicrobo-handson-heading",
    description: "magicrobo-handson-desc",
    redirectPath: "/projects",
  },
  {
    number: "4",
    buttonText: "magicrobo-make-heading",
    description: "magicrobo-make-desc",
    redirectPath: "/",
  },
];

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1200,
      lg: 1500,
      xl: 1836,
    },
  },
});

function GridItem({ number, buttonText, description, redirectPath }) {
  const history = useHistory();

  const handleButtonClick = () => {
    history.push(redirectPath);
  };

  return (
    <Card
      sx={{
        width: { md: "550px", xs: "82vw" },
        height: "345px",
        display: "flex",
        rowGap: "50px",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        background: "rgba(217, 217, 217, 0.60)",
        borderRadius: "44px",
        boxShadow: "0px 4px 6px 4px rgba(0, 0, 0, 0.25)",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row", columnGap: "30px" }}>
        <Typography sx={{ color: "#174A62", fontSize: "42px", fontWeight: "700" }}>
          {number}.
        </Typography>
        <Button
          variant="contained"
          sx={{
            background: "#222",
            minWidth: { md: "376px", xs: "50vw" },
            borderRadius: "20px",
            color: "#EBEBEB",
            fontWeight: "700",
            whiteSpace: "nowrap",
          }}
          onClick={handleButtonClick}
        >
          {buttonText}
        </Button>
      </Box>
      <Typography sx={{ width: { xs: "65vw", md: "450px" }, textAlign: "left" }}>
        {description}
      </Typography>
    </Card>
  );
}

function MagicRobo() {
  const { t } = useTranslation();

  const translatedCardData = cardData.map((item) => ({
    ...item,
    buttonText: t(item.buttonText),
    description: t(item.description)
  }));

  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth={"lg"}
        sx={{
          marginTop: "120px",
          marginBottom: "220px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        
        <Typography
          variant="h2"
          sx={{
            color: "#222",
            textAlign: { xs: "center", sm: "center", md: "left" },
            fontWeight: "700",
            lineHeight: "normal",
            maxWidth: { xs: "100vw", xs: "1000px" },
          }}
        >
          {t('magicrobo-hero-section')}
        </Typography>
        <Box sx={{ marginTop: "100px" }}>
          <img
            src="/GetStartedComponent/tools.svg"
            alt="magic"
            style={{
              width: "100%",
              maxWidth: "665px",
              paddingLeft: "0",
              ...(window.innerWidth <= 600 && {
                width: "80vw",
                paddingLeft: "10vw",
              }),
            }}
          />
        </Box>

        <Grid
          sx={{
            display: "flex",
            flexDirection: { md: "row", xs: "column" },
            columnGap: "110px",
            rowGap: "100px",
            alignItems: "center",
            marginTop: "50px",
          }}
        >
          {translatedCardData.slice(0, 2).map((data) => (
            <GridItem
              key={data.number}
              number={data.number}
              buttonText={data.buttonText}
              description={data.description}
              redirectPath={data.redirectPath}
            />
          ))}
        </Grid>

        <Grid
          sx={{
            display: "flex",
            flexDirection: { md: "row", xs: "column" },
            columnGap: "110px",
            alignItems: "center",
            rowGap: "100px",
            marginTop: { md: "210px", xs: "100px" },
          }}
        >
          {translatedCardData.slice(2, 4).map((data) => (
            <GridItem
              key={data.number}
              number={data.number}
              buttonText={data.buttonText}
              description={data.description}
              redirectPath={data.redirectPath}
            />
          ))}
        </Grid>
      </Container>
    </ThemeProvider>
  );
}

export default MagicRobo;
